import React, { useRef, useState } from "react";
// Import Swiper React components
import { SwiperSlide, Swiper } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Pagination, Autoplay } from "swiper";
import daniel from "../Assets/images/daniel.png";
import faith from "../Assets/images/faith.png";
import grace from "../Assets/images/grace.png";
import kane from "../Assets/images/kane.png";
import jane from "../Assets/images/jane.png";
import ann from "../Assets/images/ann.png";

export default function Slider() {
  const testimonial = [
    {
      name: "Daniel Uduak",
      location: "Ibadan",
      content:
        "Thank you EshopAfrica for your excellent service; my package arrived on schedule. It is truly a pleasure doing business with you, much appreciation!",
      img: daniel,
    },
    {
      name: "Damilola Faith",
      location: "Lagos",
      content:
        "I received a package from Eshopafrica today and was very pleased with the service. It took less than four days to travel from the United state to Lagos, Nigeria, and the packaging quality was excellent.",
      img: faith,
    },
    {
      name: "Amarachi Grace",
      loaction: "Lagos",
      content:
        "With eshopAfrica, you can be certain of your parcel's security and prompt delivery.         From excellent customer service to quick delivery. Without a doubt,",
      img: grace,
    },
    {
      name: "Jane",
      loaction: "Lagos",
      content:
        "I ordered lovely dresses from .....(AMAZON). I was delightfully surprised when I received my package in Lagos 3 days after. eshopAfrica is the real deal.",
      img: jane,
    },
    {
      name: "Kane",
      loaction: "Abeokuta",
      content:
        "I used the eshopAfrica virtual dollar card to purchase new camera gear and lenses. The payment was seamless and I got my camera gears in good condition. I'll be getting more items from the USA using the eshopAfrica virtual dollar card. Their card also works on every other platform.",
      img: kane,
    },
    {
      name: "Ann",
      loaction: "Abuja",
      content:
        "I am so excited that I don't have to pay multiple delivery fees when I ship from US websites. I shopped from multiplr stores and eshopAfrica delivered all my products at once. Shipping on their website was seamless using their dollar card. I'm definitely telling all my friends to try eshopAfrica.",
      img: ann,
    },
  ];
  return (
    <>
      <Swiper
        slidesPerView={1}
        spaceBetween={3}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination, Autoplay]}
        autoplay={{ delay: 5000, disableOnInteraction: true }}
      >
        {testimonial.map(({ content, name, img }, i) => {
          return (
            <SwiperSlide key={i}>
              <div className='hover:scale-105 transition flex flex-col items-center justify-center my-6 py-6'>
                <img
                  src={img}
                  className='w-32 rounded-full h-32 object-cover'
                  alt={name}
                />
                <p className='uppercase text-sm font-bold my-5'>{name}</p>
                <p className='text-sm text-center capitalize font-medium max-w-lg'>
                  {content}
                </p>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </>
  );
}
