import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Spinner } from "react-activity";
import { toast, ToastContainer } from "react-toastify";
import { baseUrl, config } from "../../../Utils/constants";
import DefaultModal from "../../Modals/defaultModal";
import Btn from "../../Button/btn";
const ConfirmPayment = () => {
  const { token } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();

  const verifyPayment = async () => {
    setIsLoading(true);
    const trans = query.get("trxref");
    const url = `${baseUrl}verifypayment/${trans}`;
    if (trans) {
      try {
        const res = await axios.post(url, {}, config(token));
        toast.success(res?.data.message);
        setIsLoading(false);
        setTimeout(() => {
          navigate("/dashboard/wallet");
        }, 1500);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    }
  };

  return (
    <div className='flex min-h-screen justify-center items-center'>
      <ToastContainer />

      <Btn
        text='Verify Payment'
        className='bg-pry text-black'
        loadingState={isLoading}
        onClick={verifyPayment}
      />

      {/* <Spinner size={30} /> */}
    </div>
  );
};

export default ConfirmPayment;
