import React from "react";

const FundCardDetails = ({ item }) => {
  const {
    name,
    dob,
    email,
    cardNo,
    amount,
    funds,
    date,
    time,
    cvv,
    validThru,
    payment,
  } = item || {};

  return (
    <>
      <div className='grid gap-2 grid-cols-12 text-xs items-center font-medium'>
        <h2>{name}</h2>
        <h2>{dob}</h2>
        <h2>{email?.length > 20 ? `${email?.slice(0, 20)}...` : email}</h2>
        <h2>{cardNo}</h2>
        <h2>{amount}</h2>
        <h2>{funds}</h2>
        <h2>{date}</h2>
        <h2>{time}</h2>
        <h2>{cvv}</h2>
        <h2>{validThru}</h2>
        <h2
          className={`${
            payment === "successful"
              ? "bg-pry2 text-green-800 "
              : payment === "failed"
              ? "bg-red-200 text-red-600"
              : "bg-gray-200"
          }  p-3 rounded-md font-medium`}
        >
          {payment}
        </h2>
      </div>
      <hr className='h-[1px] bg-gray-50 my-4' />
    </>
  );
};

export default FundCardDetails;
