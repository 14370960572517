import React, { useState } from "react";
import Export from "../../../Utils/Admin/export";
import Search from "../../../Utils/Admin/search";
import Filters from "../../../Utils/Admin/filters";
import { referrals, createdCodes } from "../../../Utils/Admin/referralData";
import Btn from "../../../Components/Button/btn";
import TotalReferrals from "../../../Components/Admin/Referrals/allReferrals";
import CreatedCode from "../../../Components/Admin/Referrals/createdCode";
import { useEffect } from "react";
import { IoCreateOutline } from "react-icons/io5";
import ReferralRecords from "../../../Components/Admin/Referrals/referralRecords";
import DefaultModal from "../../../Components/Modals/defaultModal";
import Input from "../../../Components/Form/Input";
const Referrals = () => {
  const [isLoading, setisLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const referralHeader = [
    "Name",
    "Email",
    "Phone Number",
    "Referral Code",
    "Referee Name",
    "Referee Email",
    "Referee Phone",
    "Amount",
  ];

  const recordsHeader = [
    "Name",
    "Email",
    "Phone Number",
    "Referral Code",
    "No of Referrals",
    "Status",
  ];

  const codesHeader = [
    "Name",
    "Email",
    "Phone Number",
    "Referral Code",
    "Duration",
    "Amount",
  ];

  const tabs = ["Total Referrals", "Created Codes", "Referrals Records"];

  const [activeTab, setActiveTab] = useState(0);
  const [headers, setHeaders] = useState(referralHeader);
  const [exportData, setExportData] = useState(referrals);

  useEffect(() => {
    if (activeTab === 0) {
      setHeaders(referralHeader);
      setExportData(referrals);
    } else if (activeTab === 1) {
      setHeaders(codesHeader);
      setExportData(createdCodes);
    } else if (activeTab === 2) {
      setHeaders(recordsHeader);
      setExportData(referrals);
    }
  }, [activeTab]);

  return (
    <div>
      <div className='flex justify-between gap-4 items-center my-5 bg-white p-4 rounded-md'>
        <h2 className='font-bold'>
          {tabs[activeTab]}
          <span className='text-gray-500 font-normal'>
            {" "}
            ({referrals?.length})
          </span>
        </h2>
        <div className='hidden xl:block'>
          <Search />
        </div>
        <div className='hidden md:block'>
          <Filters />
        </div>
        <Export
          data={exportData}
          filename='referrals'
          title='All Referrals'
          headers={headers}
        />
      </div>

      {/* ------------ Tab ----------------- */}
      <div className='flex flex-col lg:flex-row justify-between gap-4 lg:items-center my-6'>
        <div className='flex items-center gap-3 overflowX'>
          {tabs.map((tab, ind) => (
            <Btn
              key={ind}
              text={tab}
              className={`${
                activeTab === ind ? "bg-pry2 font-medium" : "bg-gray-100"
              } text-black text-xs`}
              onClick={() => setActiveTab(ind)}
            />
          ))}
        </div>

        <button
          className='flex items-center gap-2 bg-[#055429] text-white rounded-md p-3 px-6 border border-white shadow-md hover:bg-green-600 transition hover:scale-95 text-sm h-fit w-fit'
          onClick={() => setIsModalOpen(true)}
        >
          <IoCreateOutline className='text-xl' />
          Create Code
        </button>

        <CreateReferral
          closeModal={() => setIsModalOpen(false)}
          visibilityState={isModalOpen}
        />
      </div>

      {/* ------------ Total Referrals ----------------- */}

      {activeTab === 0 && (
        <TotalReferrals referralHeader={referralHeader} isLoading={isLoading} />
      )}

      {/* ------------ Created Code ----------------- */}
      {activeTab === 1 && (
        <CreatedCode
          createdCodes={createdCodes}
          codesHeader={codesHeader}
          isLoading={isLoading}
        />
      )}

      {/* ------------ Referral Records ----------------- */}
      {activeTab === 2 && (
        <ReferralRecords
          referralHeader={recordsHeader}
          isLoading={isLoading}
          records={referrals}
        />
      )}
    </div>
  );
};

export default Referrals;

const CreateReferral = ({ closeModal, visibilityState }) => {
  return (
    <DefaultModal closeModal={closeModal} visibilityState={visibilityState}>
      <div className='bg-white'>
        <form action='' className='flex flex-col'>
          <Input input className='border' title='Name' type='name' />
          <div className='grid grid-cols-2 gap-3'>
            <Input input className='border' title='Phone Number' type='tel' />
            <Input input className='border' title='Email' type='email' />
            <Input input className='border' title='Start Date' type='date' />
            <Input input className='border' title='End Date' type='date' />
            <Input
              input
              className='border'
              title='Referal Code'
              type='number'
            />
          </div>
        </form>
        <Btn className='bg-sec2 text-white my-5' text='Save' />
      </div>
    </DefaultModal>
  );
};
