import axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Spinner } from "react-activity";
import { useDispatch, useSelector } from "react-redux";
import SingleNotification from "../../Components/Dashboard/Notification/singleNotification";
import { baseUrl, config } from "../../Utils/constants";
import { setNotificationCount } from "../../Redux/features/dashboardSlice";

const Notification = () => {
  const [allNotifications, setAllNotifications] = useState([]);
  const { token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);

  const getNotifications = async () => {
    const url = `${baseUrl}getnotification`;
    try {
      const res = await axios.get(url, config(token));
      setAllNotifications(res.data?.notifications);
      setIsLoading(false);
      dispatch(setNotificationCount(res.data?.notifications?.length));
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getNotifications();
  }, []);

  return (
    <div>
      <h2 className='text-xl font-medium'>Notifications</h2>
      <div className='max-w-2xl'>
        {isLoading ? (
          <Spinner />
        ) : allNotifications?.length < 1 ? (
          <h2 className='my-5 text-sec italic font-medium'>
            You have no notifications
          </h2>
        ) : (
          allNotifications.map((item, index) => {
            return (
              <SingleNotification
                getNotifications={getNotifications}
                key={index}
                item={item}
              />
            );
          })
        )}
      </div>
    </div>
  );
};

export default Notification;
