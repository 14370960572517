import React from "react";
import { IoFilterOutline } from "react-icons/io5";

const Filters = () => {
  return (
    <div className='relative text-sm'>
      <IoFilterOutline className='absolute top-4 left-2 text-lg' />
      <select className='px-6 w-[200px] p-4 pl-8 border rounded-md font-medium'>
        <option value=''>Filter</option>
        {/* <option value=''>Filter</option> */}
      </select>
    </div>
  );
};

export default Filters;
