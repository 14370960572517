import React, { useState } from "react";
import { FaMapMarkerAlt } from "react-icons/fa";
import { BiDetail } from "react-icons/bi";
import Btn from "../../Button/btn";
import CancelModal from "../../Modals/cancel";
import DefaultModal from "../../Modals/defaultModal";
import Currency from "../../../Utils/currency";
import SuccessModal from "../../Modals/success";
import { useEffect } from "react";
import ErrorModal from "../../Modals/errorModal";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Spinner } from "react-activity";
import { baseUrl } from "../../../Utils/constants";
import { useSelector } from "react-redux";
import axios from "axios";
import BackButton from "../../Button/back";
const ShipmentDetails = ({
  setActiveSlide,
  isLoading,
  singleOrder,
  cancelShipment,
  cancelLoader,
}) => {
  const [isModalShown, setIsModalShown] = useState(false);
  const [isPayModalShown, setIsPayModalShown] = useState(false);
  const [isPaymentModal, setIsPaymentModal] = useState(false);
  const [isErrorModal, setIsErrorModal] = useState(false);
  const [category, setCategory] = useState("");
  const [address, setAddress] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const { token } = useSelector((state) => state.auth);
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const getAddress = async () => {
    const url = `${baseUrl}get-user-address`;
    try {
      const res = await axios.get(url, config);
      const user_address = res.data.data[0];

      if (user_address) {
        const { house_number, city, street_name, state, country } =
          user_address;
        setAddress(
          `${house_number}, ${street_name}, ${city}, ${state}, ${country}`
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAddress();
  }, []);

  const makePayment = async () => {
    const url = `${baseUrl}make-payment`;
    const shipment_id = singleOrder?.id;
    const amount = singleOrder?.delivery_price;
    setLoading(true);

    try {
      const res = await axios.post(url, { shipment_id, amount }, config);
      setIsPayModalShown(false);
      toast.success(res.data.message);
      setTimeout(() => {
        navigate("/dashboard");
      }, 3000);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setIsPayModalShown(false);
      console.log(error);
      toast.error(error.response.data.message);
    }
  };

  const getAllCategories = async () => {
    const url = `${baseUrl}get-category`;
    try {
      const res = await fetch(url);
      const { data } = await res.json();
      const shipmentCategory = data.find(
        (cat) => cat.id == singleOrder?.category_id
      );
      setCategory(shipmentCategory?.category_name || "null");
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllCategories();
  }, [isLoading, singleOrder]);

  if (isLoading) {
    return (
      <div className='flex flex-col items-center justify-center min-h-[400px]'>
        <Spinner size={50} />
      </div>
    );
  }

  console.log(singleOrder);
  return (
    <div>
      {singleOrder?.shipment_status !== "cancelled" && (
        <Btn
          className='font-medium bg-gray-200 text-gray-400 mb-10'
          onClick={() => setIsModalShown(true)}
          text='Cancel'
        />
      )}
      <ToastContainer autoClose={3000} />
      <h2 className='font-medium text-lg'>Shipment Details</h2>

      <div className='rounded-md border shadow-md my-10 gap-4 grid grid-cols-1 sm:grid-cols-2 p-5 py-10 text-sm'>
        <div>
          <div className='flex items-center gap-2'>
            <i className='text-lg'>
              <FaMapMarkerAlt />
            </i>
            <h2 className='text-base font-medium'>Ship from</h2>
          </div>
          <p className='my-3'>
            {singleOrder?.address?.address}
            {", "}
            {singleOrder?.address?.city}
            {", "}
            {singleOrder?.address?.state}
            {", "}
            {singleOrder?.address?.country}
          </p>
        </div>
        <div>
          <div className='flex items-center gap-2'>
            <i className='text-lg'>
              <FaMapMarkerAlt />
            </i>
            <h2 className='text-base font-medium'>Ship to</h2>
          </div>
          <p className='my-3'>{singleOrder?.delivery_address}</p>
        </div>
        <div>
          <div className='flex items-center gap-2'>
            <i className='text-lg'>
              <BiDetail />
            </i>
            <h2 className='text-base font-medium'>Item details</h2>
          </div>
          <div className='grid grid-cols-3 gap-2 font-medium'>
            <h2>Category</h2>
            <h2>Tracking ID</h2>
            <h2>Qty</h2>
          </div>

          {singleOrder?.shipment_details.map((item) => (
            <div className='grid grid-cols-3 gap-2' key={item}>
              <h2>{item?.category.category_name}</h2>
              <h2>{item?.package_tracking_id}</h2>
              <h2>{item?.quantity}</h2>
            </div>
          ))}

          <p className='my-3'>
            Specification:
            <span className='capitalize'>{singleOrder?.specification}</span>
          </p>
        </div>
      </div>
      <div className='flex items-center justify-between my-10'>
        <BackButton action={() => setActiveSlide(0)} />
        {singleOrder?.delivery_price && (
          <Btn
            onClick={() => setIsPayModalShown(true)}
            text={"Make Payment"}
            className='bg-pry font-medium'
          />
        )}
      </div>

      <CancelModal
        visibilityState={isModalShown}
        modalAction={() => cancelShipment(singleOrder?.id)}
        closeModal={() => setIsModalShown(false)}
        modalText='Cancel Shipment'
        loadingState={cancelLoader}
      />
      <DefaultModal
        visibilityState={isPayModalShown}
        closeModal={() => setIsPayModalShown(false)}
      >
        <div className='flex flex-col items-center justify-center'>
          <h2 className='font-medium text-2xl'>Pay</h2>
          <Currency
            className={"text-xl font-bold my-5 p-4 rounded-md bg-gray-50"}
            amount={singleOrder?.delivery_price}
          />
          <Btn
            onClick={makePayment}
            text='Continue'
            className='bg-pry font-medium'
            loadingState={loading}
          />
        </div>
      </DefaultModal>
      <SuccessModal visibilityState={isPaymentModal}>
        <h2 className='font-medium text-lg my-3'>Payment Successful</h2>
        <p className='text-sm'>
          Shipment payment of ₦{singleOrder?.delivery_price} successful
        </p>
      </SuccessModal>
      <ErrorModal visibilityState={isErrorModal}>
        <div className='text-red-600 text-center'>
          <h2 className='font-medium text-lg my-3'>Insufficent Balance</h2>
          <p className='text-sm'>
            Top up your wallet to be able to make payment
          </p>
        </div>
      </ErrorModal>
    </div>
  );
};

export default ShipmentDetails;
