import React, { useEffect, useState } from "react";
import { IoNotificationsOutline, IoSearchOutline } from "react-icons/io5";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { RiMenu5Fill } from "react-icons/ri";
import profileImage from "../../../Assets/images/profile.jpg";
import { BsChevronDown } from "react-icons/bs";
import Input from "../../Form/Input";
import { baseUrl, config } from "../../../Utils/constants";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { setDollarRate } from "../../../Redux/features/adminSlice";

const Header = ({ toggleSidebar }) => {
  // const navigateTo = useNavigate();

  const location = useLocation();
  const { adminToken, dollarRate } = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  const [activeScreen, setActiveScreen] = useState("");

  useEffect(() => {
    const path = location.pathname;
    if (path === "/secretroute/admin") {
      setActiveScreen("Dashboard");
    } else if (path.includes("admin/total-users")) {
      setActiveScreen("Users");
    } else if (path.includes("admin/virtual-dollar-card")) {
      setActiveScreen("Virtual Dollar Card");
    } else if (path.includes("admin/shipment")) {
      setActiveScreen("Shipment");
    } else if (path.includes("admin/dollar-rate")) {
      setActiveScreen("Dollar Rate");
    } else if (path.includes("admin/referrals")) {
      setActiveScreen("Referrals");
    } else if (path.includes("admin/tracking")) {
      setActiveScreen("Tracking");
    } else if (path.includes("admin/orders-and-payment")) {
      setActiveScreen("Orders and Payment");
    } else if (path.includes("admin/delivery-calculator")) {
      setActiveScreen("Delivery Calculator");
    } else {
      setActiveScreen("");
    }
  }, [location]);

  const getDollarRate = async () => {
    const url = `${baseUrl}admin/get-rate`;
    try {
      const { data } = await axios.get(url, config(adminToken));
      dispatch(setDollarRate(data.data.rate));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDollarRate();
  }, []);

  return (
    <div className='flex justify-between gap-4 items-center shadow-sm p-4'>
      <RiMenu5Fill
        className='text-2xl text-sec2 md:hidden'
        onClick={toggleSidebar}
      />
      <h2 className='font-medium'>{activeScreen}</h2>
      <div className='hidden lg:flex items-center'>
        <h2 className='font-medium'>Dollar rate: ₦{dollarRate}</h2>

        {/* <input
          type='text'
          placeholder='search for keyword'
          className='p-4 border rounded-md w-full lg:w-[400px] text-sm '
        />
        <IoSearchOutline className='text-4xl rounded-md text-gray-600 p-2 relative right-10' /> */}
      </div>
      <div className='flex items-center gap-2'>
        <div className='flex items-center gap-2'>
          <Link
            to='/secretroute/admin/notifications'
            className='flex items-center'
          >
            <IoNotificationsOutline className='text-xl' />
            {/* <h2 className='text-xs bg-red-600 text-white rounded-full w-5 h-5 flex items-center justify-center -translate-y-2 -translate-x-3'></h2> */}
          </Link>
          <img
            className='w-8 h-8 object-cover rounded-full'
            src={profileImage}
            alt='profile'
          />
          <div className='hidden sm:flex items-center gap-2'>
            <h2 className='font-medium'>Adewale Olufemi</h2>
            <BsChevronDown />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
