import React from "react";
import Btn from "../../Button/btn";
import Input from "../../Form/Input";
const Generate = ({ handleInputChange, isLoading, verifyBVN }) => {
  return (
    <div>
      <h2 className='font-medium text-2xl'>Generate Virtual Dollar Card</h2>
      <p className='text-base my-2'>
        Kindly fill out the informations correctly.
      </p>
      <form onSubmit={verifyBVN}>
        <Input
          title='BVN'
          id='bvn'
          type='number'
          input
          required={true}
          className='border-2'
          minLength={11}
          setItem={handleInputChange}
        />
        <Input
          title='Date of Birth'
          id='dob'
          type='date'
          input
          required={true}
          className='border-2'
          setItem={handleInputChange}
        />
        <Btn loadingState={isLoading} text={"Generate"} className='bg-pry' />
      </form>
    </div>
  );
};

export default Generate;
