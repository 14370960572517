import React from "react";
import walmart from "../../Assets/images/walmart.png";
import ebay from "../../Assets/images/ebay.png";
import amazon from "../../Assets/images/amazon.png";
import nike from "../../Assets/images/nike.png";
import adidas from "../../Assets/images/adidas.png";
import samsung from "../../Assets/images/samsung.png";
import { logos } from "../../Utils/logos";
const Sponsors = () => {
  const images = [walmart, ebay, amazon, nike, adidas, samsung];

  return (
    <div className='text-center  my-20'>
      <hh2 className='font-bold text-2xl text-gray-700'>
        Recieve Shipments from your favorite stores
      </hh2>
      <div className='grid grid-cols-3 sm:grid-cols-4 md:grid-cols-6 lg:grid-cols-8 gap-4 p-5'>
        {logos.map(({ image, url }, i) => (
          <a
            href={url}
            target='__blank'
            className='w-[100px] h-[100px] shadow-md p-4 rounded-full flex items-center justify-center'
          >
            <img
              className='w-32 h-32 object-contain'
              key={i}
              src={image}
              alt='sponsors'
            />
          </a>
        ))}
      </div>
    </div>
  );
};

export default Sponsors;
