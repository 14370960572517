import React, { useEffect, useState } from "react";
import logo from "../../../Assets/images/logo.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { IoCloseOutline } from "react-icons/io5";
import {
  AiFillCreditCard,
  AiFillCalculator,
  AiFillDollarCircle,
} from "react-icons/ai";
import { IoIosPeople } from "react-icons/io";
import { HiUsers } from "react-icons/hi";
import { FaTruck } from "react-icons/fa";
import { SiSlideshare, SiConvertio } from "react-icons/si";
import { CiLogout } from "react-icons/ci";

const Sidebar = ({ closeSidebar }) => {
  const sidebarLinks = [
    { text: "Dashboard", link: "" },
    { text: "Total Users", link: "total-users" },
    { text: "Virtual Dollar Card", link: "virtual-dollar-card" },
    { text: "Shipment", link: "shipment" },
    { text: "Dollar Rate", link: "dollar-rate" },
    { text: "Referrals", link: "referrals" },
    { text: "Tracking", link: "tracking" },
    { text: "Orders & Payment", link: "orders-and-payment" },
    { text: "Delivery Calculator", link: "delivery-calculator" },
  ];

  const location = useLocation();
  const [activeSlide, setActiveSlide] = useState(0);

  useEffect(() => {
    const path = location.pathname;
    if (path === "/secretroute/admin") {
      setActiveSlide(0);
    } else if (path.includes("admin/total-users")) {
      setActiveSlide(1);
    } else if (path.includes("admin/virtual-dollar-card")) {
      setActiveSlide(2);
    } else if (path.includes("admin/shipment")) {
      setActiveSlide(3);
    } else if (path.includes("admin/dollar-rate")) {
      setActiveSlide(4);
    } else if (path.includes("admin/referrals")) {
      setActiveSlide(5);
    } else if (path.includes("admin/tracking")) {
      setActiveSlide(6);
    } else if (path.includes("admin/orders-and-payment")) {
      setActiveSlide(7);
    } else if (path.includes("admin/delivery-calculator")) {
      setActiveSlide(8);
    } else {
      setActiveSlide("");
    }
  }, [location]);

  const navigateTo = useNavigate();
  const logout = () => {
    navigateTo("/secretroute/login");
  };

  return (
    <div className='shadow-md h-screen p-6 md:p-8 overflowHide'>
      <div className='flex justify-between items-center'>
        <img src={logo} alt='logo' className='w-20' />
        <IoCloseOutline
          className='text-3xl text-sec2 md:hidden'
          onClick={closeSidebar}
        />
      </div>

      <div className='flex flex-col gap-1 my-10'>
        {sidebarLinks.map(({ link, text }, ind) => {
          return (
            <Link
              to={link}
              key={ind}
              className={`${
                ind === activeSlide ? "bg-pry2 text-sec2" : "text-gray-600"
              } flex font-medium text-lg items-center gap-2 p-4 rounded-md md:hover:bg-gray-100`}
              onClick={() => {
                closeSidebar();
              }}
            >
              <i className='text-xl'>
                {ind === 0 ? (
                  <IoIosPeople />
                ) : ind === 1 ? (
                  <HiUsers />
                ) : ind === 2 ? (
                  <AiFillCreditCard />
                ) : ind === 3 ? (
                  <AiFillCreditCard />
                ) : ind === 4 ? (
                  <FaTruck />
                ) : ind === 5 ? (
                  <AiFillDollarCircle />
                ) : ind === 6 ? (
                  <SiSlideshare />
                ) : ind === 7 ? (
                  <SiConvertio />
                ) : (
                  <AiFillCalculator />
                )}
              </i>
              <h2 className='text-sm'>{text}</h2>
            </Link>
          );
        })}
        <hr className='bg-gray-100 h-[1px] my-10' />
        <button
          className='flex items-center gap-4 font-medium'
          onClick={logout}
        >
          <i className='text-xl text-red-600'>
            <CiLogout />
          </i>
          <h2>Logout</h2>
        </button>
      </div>
    </div>
  );
};

export default Sidebar;
