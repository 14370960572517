import React, { useEffect, useState } from "react";
import { FiEdit3 } from "react-icons/fi";
import DefaultModal from "../../Modals/defaultModal";
import Input from "../../Form/Input";
import Btn from "../../Button/btn";
import { useSelector } from "react-redux";
import { baseUrl, config } from "../../../Utils/constants";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import Timestamp from "../../../Utils/timeStamp";

const Order = ({ item, getOrders }) => {
  const {
    address,
    amount,
    batch_number,
    country,
    dimension,
    id,
    items,
    payment_status,
    shipment_status,
    tracking_number,
    unique_code,
    user,
    item_details,
    created_at,
  } = item || {};
  const { fullname, phone_number, email } = user || {};
  const { adminToken } = useSelector((state) => state.admin);

  const [isLoading, setisLoading] = useState(false);
  const [orderData, setOrderData] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleInputChange = (e) => {
    setOrderData({ ...orderData, [e.target.name]: e.target.value });
  };

  const updateOrder = async () => {
    setisLoading(true);
    const url = `${baseUrl}admin/update-shipments/${id}`;
    try {
      const res = await axios.post(url, orderData, config(adminToken));
      getOrders();
      setisLoading(false);
      setIsModalOpen(false);
      toast.success(res.data.message);
    } catch (error) {
      setisLoading(false);
      toast.error(
        `${error?.response?.request?.status} - ${error.response.data.message}`
      );
    }
  };

  // console.log(item);

  useEffect(() => {
    setOrderData({
      dimension,
      delivery_price: amount,
      delivery_status: shipment_status,
    });
  }, []);

  return (
    <>
      <ToastContainer autoClose={3000} />
      <div className='grid gap-2 grid-cols-12 text-xs items-start font-medium'>
        <h2>{fullname}</h2>
        <h2>{address}</h2>
        <div>
          <h2>{unique_code}</h2>
          <Timestamp time={created_at} />
        </div>
        <div>
          <h2>{phone_number}</h2>
          <h2>{email.length > 20 ? `${email.slice(0, 20)}...` : email}</h2>
        </div>
        <h2>{tracking_number}</h2>
        <div>
          <h2>{dimension}</h2>
          <h2>{batch_number}</h2>
        </div>
        <h2>{amount}</h2>

        <h2
          className={`${
            shipment_status === "successful"
              ? "bg-pry2 text-green-800"
              : shipment_status === "pending"
              ? "bg-blue-100 text-blue-800"
              : shipment_status === "processed"
              ? "bg-yellow-100 text-yellow-800"
              : "bg-red-100 text-red-800"
          } p-3 rounded-md font-medium text-center`}
        >
          {shipment_status}
        </h2>
        <h2>{payment_status}</h2>
        <h2>{country}</h2>
        <div className='overflow-hidden'>
          <h2 className='font-medium mb-3'>Details: {items};</h2>
          {/* <div className='grid grid-cols-3 gap-2 border-b-2 py-2'>
            <h2>CTY</h2>
            <h2>Qty</h2>
            <h2>Track ID</h2>
          </div> */}
          {item_details?.map(
            (
              { category: { category_name }, package_tracking_id, quantity },
              ind
            ) => (
              <div className='flex flex-col gap-3'>
                <h2>Category: {category_name}</h2>
                <h2>Qty: {quantity}</h2>
                <h2>Tracking ID{package_tracking_id}</h2>
              </div>
            )
          )}
        </div>
        <button
          className='flex items-center gap-2 justify-center border-green-700 text-green-700 border-2 rounded-md p-2 w-fit px-7'
          onClick={() => setIsModalOpen(true)}
        >
          <FiEdit3 />
          <h2>Edit</h2>
        </button>
      </div>
      <hr className='h-[1px] bg-gray-50 my-4' />
      <DefaultModal
        closeModal={() => setIsModalOpen(false)}
        visibilityState={isModalOpen}
      >
        <div className='bg-white'>
          <form
            action=''
            onSubmit={(e) => {
              e.preventDefault();
              updateOrder();
            }}
            className='flex flex-col'
          >
            <h2 className='font-medium'>Tracking ID {tracking_number}</h2>
            <Input
              input
              className='border'
              title='Dimension'
              value={orderData?.dimension || ""}
              id='dimension'
              setItem={handleInputChange}
            />
            <Input
              input
              className='border'
              title='Amount'
              value={orderData?.delivery_price || ""}
              id='delivery_price'
              setItem={handleInputChange}
            />
            <Input
              dropdown
              className='border capitalize'
              title='Delivery Status'
              id='delivery_status'
              data={["processed", "successful", "pending", "cancelled"]}
              value={orderData?.delivery_status || ""}
              setItem={handleInputChange}
            />
            <Btn
              loadingState={isLoading}
              className='bg-sec2 text-white my-5'
              text='Save'
            />
          </form>
        </div>
      </DefaultModal>
    </>
  );
};

export default Order;
