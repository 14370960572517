import React from "react";
import ellipse1 from "../../Assets/images/ellipse1.png";
import ellipse2 from "../../Assets/images/ellipse2.png";
const ContactHero = () => {
  return (
    <div className='bg-green-100 relative'>
      <section className='max-width1'>
        <div className='md:w-1/2 py-12 relative z-10'>
          <h2 className='font-medium text-xl'>Contact us</h2>
          <h2 className='font-bold my-3 text-3xl'>
            We are here to attend to your needs.
          </h2>
          <p className='text-base'>
            At eshopAfrica we understnad the importance of communication and
            prompt customer service, We have a ever ready customer
            representative that are ready to attend to you needs, complaint and
            inquries 24 hours a day, 7 days a week.
          </p>
        </div>
      </section>
      <img
        src={ellipse1}
        className='absolute bottom-0 right-0 w-24 md:w-96'
        alt='ellipse'
      />
      <img
        src={ellipse2}
        className='absolute top-0 left-0 w-8 md:w-16'
        alt='ellipse'
      />
    </div>
  );
};

export default ContactHero;
