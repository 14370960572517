import React, { useState } from "react";
import Input from "../../../Components/Form/Input";
import Btn from "../../../Components/Button/btn";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { baseUrl, config } from "../../../Utils/constants";
import { useSelector } from "react-redux";

const Notifications = () => {
  const [noteDetails, setNoteDetails] = useState({});
  const { adminToken } = useSelector((state) => state.admin);
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (e) => {
    setNoteDetails({ ...noteDetails, [e.target.name]: e.target.value });
  };

  const sendNotification = async (e) => {
    const url = `${baseUrl}admin/send-bulk-notification`;
    e.preventDefault();
    setIsLoading(true);
    try {
      const res = await axios.post(url, noteDetails, config(adminToken));
      setIsLoading(false);
      toast.success(res.data.message);
    } catch (error) {
      setIsLoading(false);
      toast.error(error.response.data.message);
    }
  };

  return (
    <div className='bg-white w-full p-5 rounded-md my-5 max-w-xl'>
      <ToastContainer autoClose={3000} />
      <h2 className='text-lg font-bold'>Send broadcast Notifications</h2>
      <form action='' onSubmit={sendNotification} className='flex flex-col'>
        <Input
          id='title'
          setItem={handleInputChange}
          input
          className='border'
          title='Title'
        />
        <Input
          textarea
          id='message'
          setItem={handleInputChange}
          className='border'
          title='Message (Not more than 160 characters)'
        />
        <Btn
          loadingState={isLoading}
          className='bg-sec2 text-white my-5'
          text='Send Notification'
        />
      </form>
    </div>
  );
};

export default Notifications;
