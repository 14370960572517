import React from "react";
import how from "../../Assets/images/how.png";
const How = () => {
  return (
    <div className='max-width1 grid grid-cols-1 md:grid-cols-2 gap-4 my-16'>
      <div>
        <h2 className='font-bold text-xl my-4'>How eshopAfrica work</h2>
        <p className='text-sm'>
          <strong> Step 1</strong>
          <br />
          Register with eShopAfrica Simply fill out and submit our simple online
          registration form. With your eShopAfrica Account, you may start
          shipping and enjoying seamless payment right now. <br />
          <br />
          <strong>Step 2</strong>
          <br /> Begin shopping and shipping from the US and other countries'
          online stores to your country Top brands and great deals can be found
          at Amazon, Walmart, and Adidas, among other places in the United
          States. create a shipment on eshopAfrica and get your goods delivered
          to your country
          <br />
          <br /> <strong>Step 3</strong>
          <br />
          Use your card or eshopAfrica virtual dollar card to make a payment.
          Let us know how you'd like to ship and we'll save you money. When your
          order arrives at our location in the United States, you will be
          alerted via email. From your eShopAfrica account, you may view and
          manage all of your packages at any time.
          <br />
          <br />
          For exportation documentation, our professionals will record product
          information and inspect each package to guarantee no items are
          damaged. In our warehouse, your products are kept safe. <br />
          <br />
          <strong>Step 4</strong>
          <br />
          Get your things quickly and without anxiety. Thanks to our long-term
          connections with our partners, you'll get speedy delivery at a
          discounted rate. Packages can be sent internationally in as little as
          2-14 days once they depart the eShopAfrica warehouse.
        </p>
      </div>
      <div className='flex items-center justify-center my-10 md:my-0'>
        <img className='md:w-2/3' src={how} alt='why' />
      </div>
    </div>
  );
};

export default How;
