import React from "react";
import dollarcard from "../../Assets/images/dollarcard.png";
import Btn from "../../Components/Button/btn";
import { useNavigate } from "react-router-dom";

const Content = () => {
  const navigateTo = useNavigate();
  return (
    <div className='max-width1 grid grid-cols-1 md:grid-cols-2 gap-4 my-16'>
      <div>
        <h2 className='font-bold text-xl my-4'>Dollar Card</h2>
        <p className='text-sm'>
          Are you fed up with the limitations of traditional payment methods?
          Say hello to the eshopAfrica virtual dollar card, your ticket to
          hassle-free online transactions! With the eshopAfrica virtual dollar
          card, you can shop globally, and pay for services and subscriptions,
          Furthermore, with top-tier security features and instant activation,
          your online transactions have never been more secure or convenient.
          Don't let borders or currencies hold you back any longer; get your
          eshopAfrica virtual dollar card today and begin exploring the world of
          limitless possibilities! <br />
          <br /> For anyone who wants to enjoy the convenience and flexibility
          of online transactions, our virtual dollar card is a game changer. You
          can say goodbye to the hassle of traditional payment methods such as
          bank transfers, credit cards, or physical cash with this innovative
          payment solution. No more waiting for your bank to process payments,
          worrying about exchange rates, or carrying bulky wallets or purses.{" "}
          <br />
          <br /> Also, our virtual dollar cards are ideal for people who prefer
          to keep their financial transactions private. Virtual cards, as
          opposed to traditional cards, do not require any personal information
          or credit history checks, making them a popular choice for people who
          value their privacy and security. <br />
          <br />
          In terms of security, eshopAfrica virtual dollar cards are extremely
          secure and include advanced security features to safeguard your
          personal and financial information. eshopAfrica uses encryption
          technology to protect your data from hackers and cybercriminals, and
          you can set spending limits and track transactions in real-time,
          giving you complete control over your finances. <br />
          <br /> In conclusion, our virtual dollar card is a must-have for
          anyone who wants to take advantage of the convenience, flexibility,
          and security that online transactions provide. eshopAfrica Virtual
          dollar cards are the ideal solution for all of your financial needs,
          whether you're shopping online, paying for subscriptions, or sending
          money to friends and family. So, why delay? Get an eshopAfrica virtual
          dollar card now and enjoy the convenience of hassle-free online
          transactions!
        </p>
        <Btn
          text='Create Account'
          className='bg-pry my-5'
          onClick={() => navigateTo("/signup")}
        />
      </div>
      <div className='flex items-center justify-center my-10 md:my-0'>
        <img className='md:w-2/3' src={dollarcard} alt='' />
      </div>
    </div>
  );
};

export default Content;
