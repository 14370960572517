import React from "react";
import Codes from "./codes";
import Table from "../Layout/Table";
import code from "../../../Assets/images/code.png";
import { IoCreateOutline } from "react-icons/io5";

const CreatedCode = ({ createdCodes, codesHeader, isLoading }) => {
  if (createdCodes.length < 1) {
    return (
      <div className='flex flex-col items-center justify-center text-center'>
        <img className='w-52' src={code} alt='code' />
        <h2 className='my-2'>No code created yet </h2>

        <button
          className='flex items-center gap-2 bg-[#055429] text-white rounded-md p-3 border border-white shadow-md hover:bg-green-600 transition hover:scale-95 text-sm h-fit w-fit my-8 px-10'
          // onClick={() => csvExporter.generateCsv(data)}
        >
          <IoCreateOutline className='text-xl' />
          Create Code
        </button>
      </div>
    );
  }
  return (
    <div>
      <Table
        cols='6'
        minSize='1100px'
        headerContent={codesHeader}
        loadingState={isLoading}
        data={createdCodes}
      >
        {createdCodes?.map((item, index) => (
          <Codes item={item} key={index} index={index} isLoading={isLoading} />
        ))}
      </Table>
    </div>
  );
};

export default CreatedCode;
