import React from "react";

const ChinaPricing = () => {
  const pricing = [
    { info: "Air cargo", pricing: "$10.99/kg minimum of 10kg" },
    { info: "Sea cargo to Nigeria", pricing: "$2.5/kg min of 200kg" },
    { info: "Phones to Nigeria", pricing: "$20/kg " },
    { info: "Air cargo to USA doorstep", pricing: "$9/kg minimum of 20kg " },
    { info: "Sea cargo to USA doorstep", pricing: "$3.5/kg minimum of 100kg " },
    { info: "Dhl to USA doorstep", pricing: "$10.50/kg min of 22kg " },
    { info: "Air cargo to UK doorstep", pricing: "$9/kg minimum of 20kg " },
    { info: "Sea cargo to UK doorstep", pricing: "$3.50/kg minimum of 200kg " },
    {
      info: "Air cargo to Canada doorstep",
      pricing: "$12/kg(USD) minimum of 20kg ",
    },
    {
      info: "Sea cargo to Canada doorstep",
      pricing: "$4.8/kg(USD) minimum of 200kg",
    },
  ];
  return (
    <section className='shadow-md py-10'>
      <div className='max-width1 '>
        <h2 className='font-medium text-xl'>China to Nigeria</h2>
        <div className='border-2 border-gray-100 rounded-md my-4'>
          {pricing.map(({ info, pricing }, ind) => {
            return (
              <div key={ind} className='grid grid-cols-2 border-b-2'>
                <h2 className='border-r-2 border-gray-100 p-2'>{info}</h2>
                <h2 className='border-r-2 border-gray-100 p-2'>{pricing}</h2>
              </div>
            );
          })}
        </div>

        <div className='grid grid-cols-1 md:grid-cols-2 gap-4 my-10'>
          <div>
            <h2 className='font-medium text-lg'>eShopAfrica Address</h2>
            <p>
              广东省深圳市宝安区福海街道新和五区三10号一楼104仓库
              朱先生(Warehouse 104, 1st Floor, No. 10, 3rd Lane, Xinhe 5th
              District, Fuhai Street, Baoan District, Shenzhen City, Guangdong
              Province)
            </p>
          </div>
          <div>
            <h2>
              Delivery phone number for vendors only{" "}
              <span className='font-medium block'>+8616675187395</span>{" "}
            </h2>
            {/* <h2 className='my-4'>
              Customer care{" "}
              <span className='font-medium block'> 1317529-2934</span>
            </h2> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ChinaPricing;
