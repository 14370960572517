import React from "react";
import how from "../../Assets/images/how.png";
const How = () => {
  return (
    <div className='max-width1 grid grid-cols-1 md:grid-cols-2 gap-4 my-16'>
      <div>
        <h2 className='font-bold text-xl my-4'>How to Ship</h2>
        <p className='text-sm'>
          <strong> Step 1</strong>
          <br />
          Sign up with eShopAfrica Simply complete and submit the short online
          registration form. You can start sending and taking advantage of
          frictionless dollar card creation right away, you can buy and ship
          from one country to another by registering with eShopAfrica.
          <br />
          <br />
          <strong>Step 2</strong>
          <br /> You can begin shopping from any of your preferred stores
          abroad. Begin receiving fantastic deals from online stores like
          Amazon, Walmart, Nike, Apple, and others. With eShopAfrica, we
          simplify delivery to your country, which was previously challenging
          when purchasing from the United States, China, Canada, or the United
          Kingdom and shipping to other countries.
          <br />
          <br /> <strong>Step 3</strong>
          <br />
          After buying or ordering from any of your favorite stores abroad, log
          in to your eShopAfrica account to create shipments and have access to
          a valid US Address. Fill out the information properly. When creating a
          shipment to avoid mistakes, and to allow us to get the complete
          details of your order. use the location information provided when
          creating a shipment, as your shipping address in your online store
          checkout. Please add the unique code to your name. We will receive and
          handle the package in your name in our warehouse, and you will be
          informed in your eShop account as soon as we receive your order, from
          the stores of purchase in your name, and process it immediately.
          <br />
          <br />
          <strong>Step 4</strong>
          <br />
          Once your shipment has been successfully created, please check your
          orders and payments to see if your shipment details are accurate. If
          the information is incorrect, you can cancel and recreate the
          shipment. You may be unable to cancel a shipment once it has been
          processed. <br />
          <br /> You will also have access to all shipment information,
          including the tracking number, amount, and all other specifics about
          your shipment once it is processed. Please make payment as soon as the
          shipment is processed to prevent delays. <br />
          <br />
          <strong>Step 5</strong>
          <br />
          Track your shipments from the US till it arrives in Nigeria on your
          mobile phone or the web Step 6: Deliver anywhere you choose Pay to
          have your shipments delivered anywhere in Nigeria or pick them up for
          free in our Lagos office. Also from China to anywhere in the world.
        </p>
      </div>
      <div className='flex items-center justify-center my-10 md:my-0'>
        <img className='md:w-2/3' src={how} alt='why' />
      </div>
    </div>
  );
};

export default How;
