import React, { useEffect, useState } from "react";
import Input from "../../../Components/Form/Input";
import Btn from "../../../Components/Button/btn";
import { useSelector } from "react-redux";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { baseUrl, config } from "../../../Utils/constants";
import Table from "../../../Components/Admin/Layout/Table";
import Types from "../../../Components/Admin/Calculator/types";
import Location from "../../../Components/Admin/Calculator/location";
import { Spinner } from "react-activity";

const DeliveryCalculator = () => {
  const [calcDetails, setCalcDetails] = useState({});
  const { adminToken } = useSelector((state) => state.admin);
  const [isLoading, setIsLoading] = useState(false);
  const [types, setTypes] = useState([]);
  const [locations, setLocations] = useState([]);

  const handleInputChange = (e) => {
    setCalcDetails({ ...calcDetails, [e.target.name]: e.target.value });
  };

  const updateCalc = async (e) => {
    const url = `${baseUrl}admin/create-delivery-type`;
    e.preventDefault();
    setIsLoading(true);
    try {
      const res = await axios.post(url, calcDetails, config(adminToken));
      setIsLoading(false);
      toast.success(res.data.message);
      getShippingType();
    } catch (error) {
      setIsLoading(false);
      toast.error(error.response.data.message);
    }
  };

  const calcDelivery = async (e) => {
    const url = `${baseUrl}admin/create-delivery-location`;
    e.preventDefault();
    setIsLoading(true);
    const data = {
      delivery_location: calcDetails?.delivery_location,
      air_price: calcDetails?.air_price,
      sea_price: calcDetails?.sea_price,
    };
    try {
      const res = await axios.post(url, data, config(adminToken));
      setIsLoading(false);
      toast.success(res.data.message);
      getLocations();
    } catch (error) {
      setIsLoading(false);
      toast.error(error.response.data.message);
    }
  };

  const getShippingType = async () => {
    const url = `${baseUrl}get-delivery-price`;
    try {
      const { data } = await axios.get(url, config(adminToken));
      setTypes(data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const getLocations = async () => {
    const url = `${baseUrl}get-delivery-location`;
    try {
      const { data } = await axios.get(url, config(adminToken));
      setLocations(data.data);
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getShippingType();
    getLocations();
  }, [isLoading]);

  if (isLoading) {
    return (
      <div className='my-10 flex items-center justify-center'>
        <Spinner />
      </div>
    );
  }

  return (
    <div className='bg-white w-full p-5 rounded-md my-5'>
      <ToastContainer autoClose={3000} />

      <div className='grid grid-cols-1 md:grid-cols-2 gap-8'>
        <div className='bg-gray-100 rounded-md p-4'>
          <Input
            input
            className='border'
            title='Select Delivery Type'
            id='delivery_type'
            setItem={handleInputChange}
          />
          <Input
            input
            className='border'
            title='Amount'
            placeholder='Enter Shipping cost'
            type='number'
            id='amount'
            setItem={handleInputChange}
          />
          <Btn
            loadingState={isLoading}
            className='bg-sec2 text-white my-5'
            text='Upload'
            onClick={updateCalc}
          />
        </div>

        <div className='bg-gray-100 rounded-md p-4'>
          <Input
            input
            className='border'
            title='Enter Delivery Location'
            id='delivery_location'
            setItem={handleInputChange}
          />

          <div className='bg-gray-100 rounded-md p-4'>
            <Input
              input
              className='border'
              title='Sea Amount'
              id='sea_price'
              setItem={handleInputChange}
            />
          </div>
          <Btn
            onClick={calcDelivery}
            loadingState={isLoading}
            className='bg-sec2 text-white my-5'
            text='Upload'
          />
        </div>
        <div className='bg-gray-100 rounded-md p-4'>
          <Input
            input
            className='border'
            title='Shipping Rate'
            id='sea_price'
            setItem={handleInputChange}
          />
          <Btn
            onClick={calcDelivery}
            loadingState={isLoading}
            className='bg-sec2 text-white my-5'
            text='Upload'
          />
        </div>
      </div>

      <div className='my-10'>
        <h2 className='text-xl font-medium mb-5'>
          Available Delivery Types ({types.length})
        </h2>

        <Table
          cols='3'
          minSize='800px'
          headerContent={["Delivery Type", "Shipping Cost", "Action"]}
          loadingState={isLoading}
          data={types}
        >
          {types?.map((item, index) => (
            <Types
              item={item}
              key={index}
              index={index}
              isLoading={isLoading}
              getShippingType={getShippingType}
            />
          ))}
        </Table>
      </div>

      <div className='my-10'>
        <h2 className='text-xl font-medium mb-5'>
          Available Delivery Locations ({locations.length})
        </h2>

        <Table
          cols='4'
          minSize='1000px'
          headerContent={[
            "Delivery Location",
            "Air Amount",
            "Sea Amount",
            "Action",
          ]}
          loadingState={isLoading}
          data={locations}
        >
          {locations?.map((item, index) => (
            <Location
              item={item}
              key={index}
              index={index}
              isLoading={isLoading}
              getLocations={getLocations}
            />
          ))}
        </Table>
      </div>
    </div>
  );
};

export default DeliveryCalculator;
