import React from "react";
import about from "../../Assets/images/about1.png";
const Mission = () => {
  return (
    <div className='max-width1 grid grid-cols-1 md:grid-cols-2 gap-4 my-16'>
      <div>
        <h2 className='font-bold text-xl my-4'>Our Mission</h2>
        <p className='text-sm'>
          To demonstrate our enthusiasm for service and excellent knowledge of
          international shipping while providing our customers with a
          trustworthy platform each time they ship with eShopAfrica. eShopAfrica
          warehouse is located in Indianapolis, USA. Also registered with
          Nigeria's Corporate Affairs Commission. <br />
          <br /> eShopAfrica was founded to provide our customers access to
          foreign stores in the United States and other countries without
          requiring them to leave their homes and to have their orders delivered
          to their doorstep. eShopAfrica also provides virtual dollar cards, in
          partnership with an American fintech, to enable payment methods
          seamless. <br />
          <br /> We make it simple for you to ship from the US and other
          countries' stores using our platform. Shipping with eShopAfrica should
          be one of the best decisions you've made so far. The interesting thing
          about eShopAfrica is that it's less expensive than other logistics
          companies. <br />
          <br /> Only eShopAfrica has the assets, knowledge, commitment to
          customers, and international experience to reliably provide quick and
          inexpensive shopping and delivery from the US and other countries
        </p>
      </div>
      <div className='flex items-center justify-center my-10 md:my-0'>
        <img className='md:w-2/3' src={about} alt='' />
      </div>
    </div>
  );
};

export default Mission;
