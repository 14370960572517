import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "../Pages/LandingPage/Home";
import DashboardHome from "../Pages/Dashboard/Home";
import LandingPage from "../Pages/LandingPage";
import Login from "../Pages/Auth/Login";
import Signup from "../Pages/Auth/Signup";
import Dashboard from "../Pages/Dashboard";
import Virtual from "../Pages/Dashboard/Virtual";
import Profile from "../Pages/Dashboard/Profile";
import Referral from "../Pages/Dashboard/Referral";
import Wallet from "../Pages/Dashboard/Wallet";
import Address from "../Pages/Dashboard/Address";
import OrderInfo from "../Pages/Dashboard/OrderInfo";
import Track from "../Pages/LandingPage/Track";
import Calculator from "../Pages/Dashboard/Calculator";
import Notification from "../Pages/Dashboard/Notification";
import About from "../Pages/LandingPage/About";
import Contact from "../Pages/LandingPage/Contact";
import WhyChoose from "../Pages/LandingPage/WhyChoose";
import HowItWorks from "../Pages/LandingPage/HowItWorks";
import ConfirmPayment from "../Components/Dashboard/Wallet/confirmPayment";
import Pricing from "../Pages/LandingPage/Pricing";
import Partner from "../Pages/LandingPage/Partner";
import ShippingGuidelines from "../Pages/LandingPage/ShippingGuidelines";
import ExchangeRate from "../Pages/LandingPage/ExchangeRate";
import VerifyOTP from "../Pages/Auth/VerifyOTP";
import DollarCard from "../Pages/LandingPage/DollarCard";
import HowToShip from "../Pages/LandingPage/HowToShip";
import Privacy from "../Pages/LandingPage/Privacy";
import ForgotPassword from "../Pages/Auth/ForgotPassword";
import Admin from "../Pages/Admin";
import AdminDashboard from "../Pages/Admin/AdminDashboard/Dashboard";
import Users from "../Pages/Admin/AdminDashboard/Users";
import AdminLogin from "../Pages/Admin/AdminLogin";
import VirtualDollarCard from "../Pages/Admin/AdminDashboard/VirtualDollarCard";
import DeliveryCalculator from "../Pages/Admin/AdminDashboard/DeliveryCalculator";
import DollarRate from "../Pages/Admin/AdminDashboard/DollarRate";
import OrdersAndPayment from "../Pages/Admin/AdminDashboard/OrdersAndPayment";
import Referrals from "../Pages/Admin/AdminDashboard/Referrals";
import Shipment from "../Pages/Admin/AdminDashboard/Shipment";
import Tracking from "../Pages/Admin/AdminDashboard/Tracking";
import Notifications from "../Pages/Admin/AdminDashboard/Notifications";

const AppRoutes = () => {
  return (
    <Router>
      <Routes>
        <Route path='paymentresponse' element={<ConfirmPayment />} />
        <Route path='/' element={<LandingPage />}>
          <Route index element={<Home />} />
          <Route path='track/:trackID/' element={<Track />} />
          <Route path='/about' element={<About />} />
          <Route path='/dollar-card' element={<DollarCard />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/pricing' element={<Pricing />} />
          <Route path='/exchange-rate' element={<ExchangeRate />} />
          <Route path='/partner-with-us' element={<Partner />} />
          <Route path='/shipping-guidelines' element={<ShippingGuidelines />} />
          <Route path='/why-choose-eShopAfrica' element={<WhyChoose />} />
          <Route path='/how-eShop-Africa-works' element={<HowItWorks />} />
          <Route path='/how-to-ship' element={<HowToShip />} />
          <Route path='/privacy-policy' element={<Privacy />} />
        </Route>
        <Route path='/signup' element={<Signup />} />
        <Route path='/login' element={<Login />} />
        <Route path='/forgot-password' element={<ForgotPassword />} />
        <Route path='/verify-signup' element={<VerifyOTP />} />

        {/* USER DASHBOARD */}
        <Route path='/dashboard' element={<Dashboard />}>
          <Route index element={<DashboardHome />} />
          <Route path='virtual-card' element={<Virtual />} />
          <Route path='referral' element={<Referral />} />
          <Route path='profile' element={<Profile />} />
          <Route path='wallet' element={<Wallet />} />
          <Route path='address' element={<Address />} />
          <Route path='order-info' element={<OrderInfo />} />
          <Route path='delivery-calculator' element={<Calculator />} />
          <Route path='notifications' element={<Notification />} />
        </Route>

        {/* ADMIN DASHBOARD`` */}
        <Route path='/secretroute/login' element={<AdminLogin />} />
        <Route path='/secretroute/admin' element={<Admin />}>
          <Route index element={<AdminDashboard />} />
          <Route path='total-users' element={<Users />} />
          <Route path='delivery-calculator' element={<DeliveryCalculator />} />
          <Route path='dollar-rate' element={<DollarRate />} />
          <Route path='orders-and-payment' element={<OrdersAndPayment />} />
          <Route path='referrals' element={<Referrals />} />
          <Route path='shipment' element={<Shipment />} />
          <Route path='tracking' element={<Tracking />} />
          <Route path='virtual-dollar-card' element={<VirtualDollarCard />} />
          <Route path='notifications' element={<Notifications />} />
        </Route>
        <Route path='*' element={<h2>404</h2>} />
      </Routes>
    </Router>
  );
};

export default AppRoutes;
