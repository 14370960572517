import React, { useEffect, useState } from "react";
import Export from "../../../Utils/Admin/export";
import Search from "../../../Utils/Admin/search";
import Filters from "../../../Utils/Admin/filters";
import User from "../../../Components/Admin/User/user";
import Table from "../../../Components/Admin/Layout/Table";
import { Spinner } from "react-activity";
import { baseUrl, config } from "../../../Utils/constants";
import axios from "axios";
import { useSelector } from "react-redux";

const Users = () => {
  const [isLoading, setIsLoading] = useState(true);
  const userHeader = [
    "Photo",
    "Name",
    "Phone Number",
    "Email",
    "Referral Code",
    "Address",
    "Status",
  ];

  const { adminToken } = useSelector((state) => state.admin);

  const [users, setUsers] = useState([]);

  const getAllUsers = async () => {
    const url = `${baseUrl}admin/users`;
    try {
      const { data } = await axios.get(url, config(adminToken));
      setIsLoading(false);
      setUsers(data.data);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllUsers();
  }, []);

  if (isLoading) {
    return (
      <div className='h-[20vh] flex items-center justify-center'>
        <Spinner />
      </div>
    );
  }

  // if (users.length < 1) {
  //   return (
  //     <div className='h-[20vh] flex items-center justify-center'>
  //       <h2 className='font-medium text-lg'>No users at the moment</h2>
  //     </div>
  //   );
  // }

  return (
    <div>
      <div className='flex justify-between gap-4 items-center my-5 bg-white p-4 rounded-md'>
        <h2 className='font-bold'>
          Total Users{" "}
          <span className='text-gray-500 font-normal'>({users?.length})</span>
        </h2>
        <div className='hidden xl:block'>
          <Search />
        </div>
        <div className='hidden md:block'>
          <Filters />
        </div>
        <Export
          data={users}
          filename='users'
          title='All Users'
          headers={userHeader}
        />
      </div>

      <Table
        cols='7'
        minSize='1200px'
        headerContent={userHeader}
        loadingState={isLoading}
        data={users}
      >
        {users?.map((item, index) => (
          <User item={item} key={index} index={index} isLoading={isLoading} />
        ))}
      </Table>
    </div>
  );
};

export default Users;
