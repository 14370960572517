import React from "react";

const Referral = ({ item }) => {
  const { name, tel, email, referralCode, count } = item || {};
  return (
    <>
      <div className='grid gap-2 grid-cols-8 text-xs items-center font-medium'>
        <h2>{name}</h2>
        <h2>{email}</h2>
        <h2>{tel}</h2>
        <h2>{referralCode}</h2>
        <h2>{count}</h2>
      </div>
      <hr className='h-[1px] bg-gray-50 my-4' />
    </>
  );
};

export default Referral;
