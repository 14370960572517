import React, { useEffect } from "react";
import WhyHero from "../../Components/WhyChoose/hero";
import Why from "../../Components/WhyChoose/why";

const WhyChoose = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <div>
      <WhyHero />
      <Why />
    </div>
  );
};

export default WhyChoose;
