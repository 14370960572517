import React from "react";
import partner from "../../Assets/images/partner.png";

const PartnerWithUs = () => {
  return (
    <div className='max-width1'>
      <div className='flex flex-col md:flex-row gap-5 items-center'>
        <img className='sm:w-56' src={partner} alt='partner' />
        <div>
          <h2 className='font-bold text-2xl'>Partner with us</h2>
          <p className='text-sm my-4 '>
            At eShopAfrica, we welcome partnerships from anywhere in the world
            and look forward to working with you. For further information,
            please contact any of our offices in the United States or Nigeria.
            You can contact us using the information provided below.
          </p>
          <div className='grid grid-cols-1 sm:grid-cols-2 gap-4 text-sec text-sm'>
            <p>
              United States <br /> eshopAfrica <br /> 1009 country club rd 46234{" "}
              <br /> Indianapolis IN. <br /> +1 (317) 529-2934
            </p>
            <p>
              Nigeria <br /> 7b budland street Akiode Berger Lagos <br />{" "}
              +2348132578261 <br /> Email: info@eshopafrica.co
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartnerWithUs;
