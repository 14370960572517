import React from "react";
import heroImg from "../../Assets/images/partnerhero.png";
import { useNavigate } from "react-router-dom";
import Btn from "../Button/btn";
const PartnerHero = () => {
  const navigateTo = useNavigate();
  return (
    <div className='relative md:h-[450px]'>
      <div className='max-width1 relative z-10 h-full flex flex-col justify-center'>
        <h2 className='font-medium text-lg'>Partner</h2>
        <h4 className='font-bold text-3xl my-8 max-w-md'>
          Enjoy seamless delivery when you partner with us.
        </h4>
        <Btn
          className='bg-pry font-medium'
          text='Create Account'
          onClick={() => navigateTo("/signup")}
        />
      </div>
      <div className='bg-blur bg-gradient-to-l absolute top-0 left-0 w-full h-full  from-gray-500 to-gray-50'>
        <img
          src={heroImg}
          className='w-full mix-blend-overlay h-full object-cover'
          alt='hero'
        />
      </div>
    </div>
  );
};

export default PartnerHero;
