import React from "react";
import { useEffect } from "react";
import About from "../../Components/Home/about";
import CTA from "../../Components/Home/cta";
import Hero from "../../Components/Home/hero";
import How from "../../Components/Home/how";
import Rate from "../../Components/Home/rate";
import Shop from "../../Components/Home/shop";
import Sponsors from "../../Components/Home/sponsors";
import Testimonial from "../../Components/Home/testimonial";
import Virtual from "../../Components/Home/virtual";
import Why from "../../Components/Home/why";
import { AnimationOnScroll } from "react-animation-on-scroll";
import Section from "../../Components/Home/section";

const Home = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <div className=''>
      <Hero />
      <AnimationOnScroll animateIn='animate__backInLeft'>
        <Sponsors />
      </AnimationOnScroll>
      <AnimationOnScroll animateIn='animate__zoomIn'>
        <About />
      </AnimationOnScroll>
      <How />
      <Why />
      <AnimationOnScroll animateIn='animate__backInLeft'>
        <CTA />
      </AnimationOnScroll>
      <Virtual />
      <AnimationOnScroll animateIn='animate__zoomIn'>
        <Section />
      </AnimationOnScroll>
      <Shop />
      <Rate />
      <Testimonial />
    </div>
  );
};

export default Home;
