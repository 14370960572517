import React from "react";

const Possible = () => {
  return (
    <div className='max-width1 my-10 text-sec'>
      <p>
        <span className='font-medium block mb-3 text-lg'>
          Is it possible for me to ship it?
        </span>
        Are you trying to figure out if you can send anything internationally?
        Due to customs legislation and delivery company or airline constraints,
        we are unable to transport some items. Banned items vary depending on
        which country you're shipping to however, the following items are
        generally prohibited from being shipped internationally from the United
        States:
      </p>
    </div>
  );
};

export default Possible;
