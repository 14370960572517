export const cardUserData = [
  {
    name: "Adekoya Adeola",
    dob: "30/09/2000",
    funds: "$4,000",
    pin: "1234",
    validThru: "09/23",
    cvv: "357",
    status: "enabled",
  },
  {
    name: "Adekoya Adeola",
    dob: "30/09/2000",
    funds: "$4,000",
    pin: "1234",
    validThru: "09/23",
    cvv: "357",
    status: "disabled",
  },
  {
    name: "Adekoya Adeola",
    dob: "30/09/2000",
    funds: "$4,000",
    pin: "1234",
    validThru: "09/23",
    cvv: "357",
    status: "enabled",
  },
  {
    name: "Adekoya Adeola",
    dob: "30/09/2000",
    funds: "$4,000",
    pin: "1234",
    validThru: "09/23",
    cvv: "357",
    status: "enabled",
  },
  {
    name: "Adekoya Adeola",
    dob: "30/09/2000",
    funds: "$4,000",
    pin: "1234",
    validThru: "09/23",
    cvv: "357",
    status: "disabled",
  },
  {
    name: "Adekoya Adeola",
    dob: "30/09/2000",
    funds: "$4,000",
    pin: "1234",
    validThru: "09/23",
    cvv: "357",
    status: "enabled",
  },
];

export const fundCardRequest = [
  {
    name: "Adekoya Adeola",
    cardNo: "3242335398424",
    dob: "30/09/2000",
    funds: "$4,000",
    amount: "$3,400",
    email: "adenuga@gmail.com",
    validThru: "09/23",
    cvv: "357",
    date: "30/09/2000",
    time: "4:00PM",
    payment: "successful",
  },
  {
    name: "Adekoya Adeola",
    cardNo: "3242335398424",
    dob: "30/09/2000",
    funds: "$4,000",
    amount: "$3,400",
    email: "adenuga@gmail.com",
    validThru: "09/23",
    cvv: "357",
    date: "30/09/2000",
    time: "4:00PM",
    payment: "successful",
  },
  {
    name: "Adekoya Adeola",
    cardNo: "3242335398424",
    dob: "30/09/2000",
    funds: "$4,000",
    amount: "$3,400",
    email: "adenuga@gmail.com",
    validThru: "09/23",
    cvv: "357",
    date: "30/09/2000",
    time: "4:00PM",
    payment: "successful",
  },
];
export const newCardRequest = [
  {
    name: "Adekoya Adeola",
    // cardNo: "3242335398424",
    dob: "30/09/2000",
    funds: "$4,000",
    amount: "$3,400",
    email: "adenuga@gmail.com", // validThru: "09/23",
    // cvv: "357",
    date: "30/09/2000",
    time: "4:00PM",
    payment: "successful",
  },
  {
    name: "Adekoya Adeola",
    // cardNo: "3242335398424",
    dob: "30/09/2000",
    funds: "$4,000",
    amount: "$3,400",
    email: "adenuga@gmail.com", // validThru: "09/23",
    // cvv: "357",
    date: "30/09/2000",
    time: "4:00PM",
    payment: "successful",
  },
  {
    name: "Adekoya Adeola",
    // cardNo: "3242335398424",
    dob: "30/09/2000",
    funds: "$4,000",
    amount: "$3,400",
    email: "adenuga@gmail.com", // validThru: "09/23",
    // cvv: "357",
    date: "30/09/2000",
    time: "4:00PM",
    payment: "successful",
  },
];
