import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import Address from "../../Components/Dashboard/Home/address";
import CreateShipment from "../../Components/Dashboard/Home/createShipment";
import Shipment from "../../Components/Dashboard/Home/shipment";
import ShipmentSuccess from "../../Components/Dashboard/Home/shipmentSuccess";
import ShipmentSummary from "../../Components/Dashboard/Home/shipmentSummary";
import { baseUrl } from "../../Utils/constants";

const Home = () => {
  const [activeSlide, setActiveSlide] = useState(0);
  const [inputItems, setInputItems] = useState({});
  const [allCategories, setAllCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [addressDetails, setAddressDetails] = useState({});
  const [allCountries, setAllCountries] = useState([]);
  const [categoryList, setCatgoryList] = useState([]);

  const { token } = useSelector((state) => state.auth);

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const nextSlide = () => {
    setActiveSlide(activeSlide + 1);
  };
  const handleInputChange = (e) => {
    setInputItems({ ...inputItems, [e.target.name]: e.target.value });
  };

  const getAllCategories = async () => {
    const url = `${baseUrl}get-category`;
    try {
      const res = await fetch(url);
      const { data } = await res.json();
      setAllCategories(data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async () => {
    nextSlide();
  };

  const createShipment = async () => {
    setIsLoading(true);

    const categoryData = categoryList.map(
      ({ quantity, category, tracking_id }) => {
        const selectedCategory = allCategories?.find(
          (cat) => cat.category_name === category
        );
        const newData = {
          quantity,
          category_id: selectedCategory?.id,
          package_tracking_id: tracking_id,
        };
        return newData;
      }
    );

    const selectedCountry = allCountries?.find(
      (country) => country.name === inputItems.ship_from
    );
    const country_id = selectedCountry?.id;

    const inputs = {
      store_name: "abc",
      specification: inputItems?.specification,
      quantity: inputItems?.quantity,
      details: categoryData,
      country_id,
      delivery_address: inputItems?.delivery_address,
      state: inputItems?.state,
      country: inputItems?.country,
      zip_code: inputItems?.zip_code,
      tracking_id: inputItems?.tracking_id,
    };
    const url = `${baseUrl}create-shipment`;
    let msg;
    try {
      const { data } = await axios.post(url, inputs, config);
      setIsLoading(false);
      setAddressDetails(data.data);
      msg = "Shipment created successfully";
      toast.success(msg);
      setInputItems({});
      setCatgoryList([]);
      nextSlide();
    } catch (error) {
      console.log(error);
      msg = "An error occurred while creating shipment";
      setIsLoading(false);
      toast.error(msg);
    }
  };

  useEffect(() => {
    getAllCategories();
  }, []);

  const getAllCountries = async () => {
    const url = `${baseUrl}get-country`;
    try {
      const res = await axios.get(url, config);
      setAllCountries(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const addToList = () => {
    const quantity = inputItems?.quantity;
    const category = inputItems?.category;
    const tracking_id = inputItems?.tracking_id;
    if (category && quantity && tracking_id) {
      setCatgoryList((currentCat) => [
        ...currentCat,
        { quantity, category, tracking_id },
      ]);
      setInputItems({
        ...inputItems,
        category: "",
        tracking_id: "",
        quantity: "",
      });
    }
  };

  const removeCat = (id) => {
    const filteredCat = categoryList.filter((item, ind) => ind !== id);
    setCatgoryList(filteredCat);
  };

  useEffect(() => {
    getAllCountries();
  }, []);

  return (
    <div className=''>
      <ToastContainer autoClose={3000} />
      {activeSlide === 0 ? (
        <Shipment nextSlide={nextSlide} />
      ) : activeSlide === 1 ? (
        <CreateShipment
          handleInputChange={handleInputChange}
          nextSlide={nextSlide}
          inputItems={inputItems}
          allCategories={allCategories}
          handleSubmit={handleSubmit}
          allCountries={allCountries}
          setActiveSlide={setActiveSlide}
          addToList={addToList}
          removeCat={removeCat}
          categoryList={categoryList}
        />
      ) : activeSlide === 2 ? (
        <ShipmentSummary
          setActiveSlide={setActiveSlide}
          inputItems={inputItems}
          nextSlide={nextSlide}
          createShipment={createShipment}
          isLoading={isLoading}
          categoryList={categoryList}
        />
      ) : (
        <ShipmentSuccess setActiveSlide={setActiveSlide} />
      )}
    </div>
  );
};

export default Home;
