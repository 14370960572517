import copy from "copy-to-clipboard";

export const baseUrl = `https://api.eshopafrica.co/api/v1/`;
export const imageUrl = `https://api.eshopafrica.co/`;

export const dollarRate = 750.0;

export const copyText = (text) => {
  copy(text);
  alert(`${text} copied to clipboard`);
};

export const config = (token) => {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
};

export const createRequest = (path) => {
  const url = `${baseUrl}${path}`;
  let msg;
};

// const createDollarCard = async () => {
//   const url = `${baseUrl}create-dollar-details`;
//   let msg;
//   setIsLoading(true);
//   try {
//     const res = await axios.post(url, virtualCardDetails, config(token));
//     msg = res.data.message;
//     toast.success(msg);
//     nextSlide();
//     setIsLoading(false);
//   } catch (error) {
//     msg = error.response.data.message;
//     toast.error(msg);
//     setIsLoading(false);
//   }
// };
