import React from "react";
import walmart from "../../Assets/images/walmart.png";
import ebay from "../../Assets/images/ebay.png";
import amazon from "../../Assets/images/amazon.png";
import nike from "../../Assets/images/nike.png";
import adidas from "../../Assets/images/adidas.png";
import samsung from "../../Assets/images/samsung.png";
import ms from "../../Assets/images/mc.png";
import gucci from "../../Assets/images/gucci.png";
import burbery from "../../Assets/images/burbery.png";
import zara from "../../Assets/images/zara.png";
import place from "../../Assets/images/place.png";
import disney from "../../Assets/images/disney.png";
import carter from "../../Assets/images/carter.png";
import lacoste from "../../Assets/images/lascoste.png";
import dior from "../../Assets/images/dior.png";
import michael from "../../Assets/images/michael.png";
import polo from "../../Assets/images/polo.png";
import old from "../../Assets/images/old.png";
import fashionnova from "../../Assets/images/fashionnova.png";
const Sponsors = () => {
  const partners = [
    walmart,
    ebay,
    amazon,
    nike,
    adidas,
    samsung,
    ms,
    gucci,
    burbery,
    zara,
    place,
    disney,
    carter,
    lacoste,
    dior,
    michael,
    polo,
    old,
    fashionnova,
  ];
  return (
    <div className='max-width1 my-10'>
      <h2 className='text-center p-3 font-bold text-2xl'>
        Receive shipments from your favorite stores
      </h2>

      <div className='grid grid-cols-3 md:grid-cols-6 gap-4 my-5'>
        {partners.map((image, index) => {
          return (
            <img
              className='w-full h-20 object-contain'
              src={image}
              key={index}
              alt='logo'
            />
          );
        })}
      </div>
    </div>
  );
};

export default Sponsors;
