import React, { useEffect, useState } from "react";
import users from "../../../Assets/images/users.png";
import card from "../../../Assets/images/card5.png";
import shipment from "../../../Assets/images/shipment.png";
import delShipment from "../../../Assets/images/delShipment.png";
import canShipment from "../../../Assets/images/canShipment.png";
import pendShipment from "../../../Assets/images/pendShipment.png";
import { baseUrl, config } from "../../../Utils/constants";
import { useSelector } from "react-redux";
import axios from "axios";
import { Spinner } from "react-activity";

const AdminDashboard = () => {
  const { adminToken } = useSelector((state) => state.admin);

  const [isLoading, setIsLoading] = useState(true);
  const [dashDetails, setDashDetails] = useState({});

  const getDashboardData = async () => {
    const url = `${baseUrl}admin/dashboard`;
    try {
      const { data } = await axios.get(url, config(adminToken));
      setIsLoading(false);
      setDashDetails(data);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getDashboardData();
  }, []);

  const {
    cancelled_shipments,
    delivered_shipments,
    pending_shipments,
    total_dollar_cards,
    total_shipments,
    total_users,
  } = dashDetails || {};

  const data = [
    { title: "Total Number of users", numbers: total_users, image: users },
    {
      title: "Total Number of dollar card users",
      numbers: total_dollar_cards,
      image: card,
    },
    {
      title: "Total Number of shipments",
      numbers: total_shipments,
      image: shipment,
    },
    {
      title: "Total Number of delivered shipments",
      numbers: delivered_shipments,
      image: delShipment,
    },
    {
      title: "Total Number of pending shipments",
      numbers: pending_shipments,
      image: pendShipment,
    },
    {
      title: "Total Number of cancelled shipments",
      numbers: cancelled_shipments,
      image: canShipment,
    },
  ];

  if (isLoading) {
    return (
      <div className='h-[20vh] flex items-center justify-center'>
        <Spinner />
      </div>
    );
  }
  return (
    <div>
      <div className='grid my-6 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4'>
        {data.map(({ title, numbers, image }, ind) => {
          return (
            <div
              key={ind}
              className='bg-white rounded-md p-4 flex flex-col justify-between'
            >
              <h2 className='text-gray-500 text-sm'>{title}</h2>
              <div className='flex justify-between gap-4 items-center my-3'>
                <h2 className='text-2xl font-medium'>{numbers}</h2>
                <img className='w-7' src={image} alt='image' />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AdminDashboard;
