import { useEffect } from "react";
// import search from "../images/search.png";
import SingleFaq from "./singleFaq";

const Faq = () => {
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  const questions = [
    {
      q: "How does eshopAfrica operate?",
      a: "International customers can utilize eshopAfrica to get a US shipping address to use when ordering from US online retailers. Your purchases are shipped to you, and you save a lot of money on international shipping.",
    },
    {
      q: "How can I calculate the cost of international shopping?",
      a: "You can estimate your shipping costs before you buy an item by asking the merchant for the weight and dimensions of the package they will ship to us. You can then enter that information into our shipping rate calculator.",
    },
    {
      q: "Is it possible for eshopAfrica to ship my product?",
      a: "We can ship almost anything for you, including clothing, cosmetics, toys, cell phones, laptop computers, and much more. The items we cannot ship are restricted by the US government and/or are not permitted for import into your country. We cannot, for example, ship any items that require an export license or a license exception to be filed. We will gladly ship any items that do not violate our compliance policy.",
    },
    {
      q: "What exactly is eshopAfrica?",
      a: "eShopAfrica is a purpose-driven Logitech company based in Indiana, USA. Also registered with the Corporate Affairs Commission of Nigeria. We at eshopAfrica give African businesses access to global commerce and high-quality products. Our mission is to close the trade  gap, make import simple and give people more access to the international market. Additionally, we offer free virtual dollar cards for simple purchases at foreign retailers.",
    },
  ];
  return (
    <div className='max-width1 my-10'>
      <h1 className='font-bold text-xl'>Frequently Asked Questions</h1>

      <div className=''>
        {questions.map((question, i) => {
          return <SingleFaq index={i} question={question} key={i} />;
        })}
      </div>
    </div>
  );
};

export default Faq;
