import React, { useState } from "react";
import Export from "../../../Utils/Admin/export";
import Search from "../../../Utils/Admin/search";
import Filters from "../../../Utils/Admin/filters";
import Table from "../../../Components/Admin/Layout/Table";
import Shipment from "../../../Components/Admin/Shipment/shipment";
import { baseUrl, config } from "../../../Utils/constants";
import axios from "axios";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { Spinner } from "react-activity";
import { sortList } from "../../../Utils/sort";

const Shipments = () => {
  const [isLoading, setisLoading] = useState(true);
  const shipmentHeader = [
    "Name",
    "Email",
    "Phone Number",
    "Address",
    "Ship From",
    "Status",
  ];

  const { adminToken } = useSelector((state) => state.admin);
  const [allShipments, setAllShipments] = useState([]);

  const getShipments = async () => {
    const url = `${baseUrl}admin/get-shipments`;

    try {
      const { data } = await axios.get(url, config(adminToken));
      setisLoading(false);
      setAllShipments(data.data);
    } catch (error) {
      console.log(error);
      setisLoading(false);
    }
  };

  useEffect(() => {
    getShipments();
  }, []);

  if (isLoading) {
    return (
      <div className='h-[20vh] flex items-center justify-center'>
        <Spinner />
      </div>
    );
  }

  if (allShipments.length < 1) {
    return (
      <div className='h-[20vh] flex items-center justify-center'>
        <h2 className='font-medium text-lg'>No shipment at the moment</h2>
      </div>
    );
  }

  sortList(allShipments);

  return (
    <div>
      <div className='flex justify-between gap-4 items-center my-5 bg-white p-4 rounded-md'>
        <h2 className='font-bold'>
          All Shipments
          <span className='text-gray-500 font-normal'>
            ({allShipments?.length})
          </span>
        </h2>
        <div className='hidden xl:block'>
          <Search />
        </div>
        <div className='hidden md:block'>
          <Filters />
        </div>
        <Export
          data={allShipments}
          filename='shipments'
          title='All Shipments'
          headers={shipmentHeader}
        />
      </div>

      <Table
        cols='6'
        minSize='1100px'
        headerContent={shipmentHeader}
        loadingState={isLoading}
        data={allShipments}
      >
        {allShipments?.map((item, index) => (
          <Shipment
            item={item}
            key={index}
            index={index}
            isLoading={isLoading}
          />
        ))}
      </Table>
    </div>
  );
};

export default Shipments;
