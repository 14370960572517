import React, { useEffect, useState } from "react";
import Input from "../../../Components/Form/Input";
import Btn from "../../../Components/Button/btn";
import { useSelector } from "react-redux";
import { baseUrl, config } from "../../../Utils/constants";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

const DollarRate = () => {
  const { dollarRate, adminToken } = useSelector((state) => state.admin);
  const [rate, setRate] = useState("");
  const [cost, setCost] = useState("");
  const [shippingCost, setShippingCost] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const createDollarRate = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const url = `${baseUrl}admin/create-dollar-rate`;
    try {
      const { data } = await axios.post(url, { rate }, config(adminToken));
      setIsLoading(false);
      toast.success(data?.message);
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message);
      setIsLoading(false);
    }
  };

  const createShippingRate = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let url;

    if (shippingCost !== 0) {
      url = `${baseUrl}admin/edit-shipping-cost/${shippingCost?.id}`;
    } else {
      console.log("false");
      url = `${baseUrl}admin/add-shipping-cost`;
    }

    try {
      const { data } = await axios.post(
        url,
        { amount: cost },
        config(adminToken)
      );
      setIsLoading(false);
      toast.success(data?.message);
      getShippingRate();
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message);
      setIsLoading(false);
    }
  };

  const getShippingRate = async () => {
    setIsLoading(true);
    const url = `${baseUrl}get-shipping-cost`;
    try {
      const { data } = await axios.get(url, config(adminToken));
      setIsLoading(false);
      setCost(data.data.shipping_cost);
      setShippingCost(data.data);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getShippingRate();
    setRate(dollarRate);
  }, []);

  return (
    <div>
      <div className='bg-white w-full p-5 rounded-md my-5 max-w-xl'>
        <ToastContainer autoClose={3000} />

        <h2 className='font-medium text-lg'>Current Dollar Rate</h2>
        <p>1$ = ₦{dollarRate}</p>
        <form action='' onSubmit={createDollarRate}>
          <Input
            input
            className='border'
            id='rate'
            setItem={(e) => setRate(e.target.value)}
            placeholder='Enter current rate'
            value={rate || ""}
          />

          <Btn
            loadingState={isLoading}
            className='bg-sec2 text-white my-5'
            text='Update'
          />
        </form>
      </div>
      <div className='bg-white w-full p-5 rounded-md my-5 max-w-xl'>
        <ToastContainer autoClose={3000} />

        <h2 className='font-medium text-lg'>Current Shipping Rate</h2>
        <p>Cost = ${shippingCost?.shipping_cost || 0}</p>
        <form action='' onSubmit={createShippingRate}>
          <Input
            input
            className='border'
            id='rate'
            setItem={(e) => setCost(e.target.value)}
            placeholder='Enter current rate'
            value={cost || ""}
          />

          <Btn
            loadingState={isLoading}
            className='bg-sec2 text-white my-5'
            text='Update'
          />
        </form>
      </div>
    </div>
  );
};

export default DollarRate;
