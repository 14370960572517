import React from "react";
import { useEffect } from "react";
import PrivacyHero from "../../Components/PrivacyPolicy/hero";
import Content from "../../Components/PrivacyPolicy/content";

const Privacy = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <div>
      <PrivacyHero />
      <Content />
    </div>
  );
};

export default Privacy;
