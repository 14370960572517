import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { baseUrl, config } from "../../../../Utils/constants";
import Btn from "../../../Button/btn";
import Input from "../../../Form/Input";
import DefaultModal from "../../../Modals/defaultModal";
import SuccessModal from "../../../Modals/success";

const CreditWallet = ({ setIsTopupModalVisible, isTopupModalVisible }) => {
  const { token } = useSelector((state) => state.auth);
  const [isTopupCompleted, setIsTopupCompleted] = useState(false);
  const [amount, setAmount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsTopupCompleted(false);
    }, 3000);
    return () => clearTimeout(timeout);
  }, [isTopupCompleted]);

  const makePayment = async () => {
    setIsLoading(true);
    const url = `${baseUrl}topup`;
    try {
      const res = await axios.post(url, { amount }, config(token));
      console.log(res);
      const payLink = res.data.url;
      window.location.replace(payLink);
      setIsLoading(false);
      setIsTopupModalVisible(false);
      // setIsTopupCompleted(true);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  return (
    <>
      <DefaultModal
        visibilityState={isTopupModalVisible}
        closeModal={() => setIsTopupModalVisible(false)}
      >
        <Input
          input
          title='Amount'
          type='number'
          className={"border-2 font-medium"}
          value={amount}
          setItem={(e) => setAmount(e.target.value)}
        />
        <Btn
          text={"Make Payment"}
          className='bg-pry w-full my-4 font-medium'
          onClick={makePayment}
          loadingState={isLoading}
        />
      </DefaultModal>
      <SuccessModal visibilityState={isTopupCompleted}>
        <h2 className='text-lg font-medium my-2'>Payment Successful </h2>
        <p className='text-sm'>You paid NGN 10,000 to EshopAfrica</p>
      </SuccessModal>
    </>
  );
};

export default CreditWallet;
