import React from "react";
// import cardLogo from "../../../../Assets/images/visa2.png";

const Card = ({ dollarCardDetails }) => {
  const {
    card_firstname,
    card_lastname,
    card_number,
    card_type,
    cvv,
    valid_thru,
    dollar_wallet,
  } = dollarCardDetails || {};

  return (
    <div className='bg-black text-white p-6 rounded-xl my-2 max-w-[336px]'>
      <h2 className='text-xs'>Available Funds</h2>
      <h2 className='my-1 font-bold text-base'>
        ${dollar_wallet?.wallet_balance || "0.0"}
      </h2>
      {/* <h2 className='text-sm'>Available to spend</h2> */}
      <p className='text-sm my-2 font-medium'>
        {card_number || "xxxx xxxx xxxx xxxx"}
      </p>
      <div className='grid grid-cols-2 text-xs'>
        <div className='flex items-center gap-2 font-light'>
          <h2>
            Valid <br /> thru
          </h2>
          <h2>{valid_thru || "xx/xx"}</h2>
        </div>
        <div>
          <h2 className='font-light'>{cvv}</h2>
        </div>
      </div>
      <div className='flex justify-between gap-4 items-center my-3'>
        <h2 className='text-sm font-medium'>
          {card_firstname} {card_lastname}
        </h2>
        <h2>{card_type}</h2>
      </div>
    </div>
  );
};

export default Card;
