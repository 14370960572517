import React, { useEffect, useState } from "react";
import Export from "../../../Utils/Admin/export";
import Search from "../../../Utils/Admin/search";
import Filters from "../../../Utils/Admin/filters";
import Table from "../../../Components/Admin/Layout/Table";
import Batch from "../../../Components/Admin/Tracking/batch";
import find from "../../../Assets/images/find.png";
import { IoMdAdd } from "react-icons/io";
import DefaultModal from "../../../Components/Modals/defaultModal";
import Input from "../../../Components/Form/Input";
import Btn from "../../../Components/Button/btn";
import { baseUrl, config } from "../../../Utils/constants";
import axios from "axios";
import { useSelector } from "react-redux";
import { Spinner } from "react-activity";

const Tracking = () => {
  const [isLoading, setisLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { adminToken } = useSelector((state) => state.admin);
  const [newBatch, setNewBatch] = useState({});
  const [batches, setBatches] = useState([]);

  const handeleInputChange = (e) => {
    setNewBatch({ ...newBatch, [e.target.name]: e.target.value });
  };

  const trackingHeader = [
    "Serial Number",
    "Batch Number",
    "Start Date",
    "End Date",
    "Action",
  ];

  const getAllBatches = async () => {
    setisLoading(true);
    const url = `${baseUrl}admin/get-batch`;
    try {
      const res = await axios.get(url, config(adminToken));
      setBatches(res.data.date);
      setisLoading(false);
    } catch (error) {
      console.log(error);
      setisLoading(false);
    }
  };

  console.log(batches);

  const createBatch = async (e) => {
    e.preventDefault();
    const url = `${baseUrl}admin/create-batch`;
    setisLoading(true);
    try {
      const res = await axios.post(url, newBatch, config(adminToken));
      getAllBatches();
      setisLoading(false);
      setIsModalOpen(false);
    } catch (error) {
      setisLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getAllBatches();
  }, []);

  if (isLoading) {
    return (
      <div className='h-[20vh] flex items-center justify-center'>
        <Spinner />
      </div>
    );
  }

  if (batches?.length < 1) {
    return (
      <>
        <div className='flex flex-col items-center justify-center'>
          <img src={find} className='w-52' alt='find' />
          <h2 className='my-4 text-base'>No Batch Number Created yet</h2>
          <button
            className='flex items-center bg-sec2 text-white p-3 rounded-md text-sm gap-2 px-10 hover:bg-sec transition'
            onClick={() => setIsModalOpen(true)}
          >
            <IoMdAdd className='text-base' color='white' />
            Create Batch Number
          </button>
        </div>
        <CreateBatch
          closeModal={() => setIsModalOpen(false)}
          visibilityState={isModalOpen}
          handeleInputChange={handeleInputChange}
          createBatch={createBatch}
          isLoading={isLoading}
        />
      </>
    );
  }

  return (
    <div>
      <div className='flex justify-between gap-4 items-center my-5 bg-white p-4 rounded-md'>
        <h2 className='font-bold'>
          Batch Number
          <span className='text-gray-500 font-normal'>({batches?.length})</span>
        </h2>
        <div className='hidden xl:block'>
          <Search />
        </div>
        <div className='hidden md:block'>
          <Filters />
        </div>
        <Export
          data={batches}
          filename='tracking'
          title='Batches'
          headers={trackingHeader}
        />
      </div>

      <button
        className='flex items-center text-sec2 my-4 rounded-md text-sm gap-2 hover:text-gray-900 transition'
        onClick={() => setIsModalOpen(true)}
      >
        <IoMdAdd className='text-base' />
        Create Batch Number
      </button>

      <CreateBatch
        closeModal={() => setIsModalOpen(false)}
        visibilityState={isModalOpen}
        handeleInputChange={handeleInputChange}
        createBatch={createBatch}
        isLoading={isLoading}
      />

      <Table
        cols='5'
        minSize='900px'
        headerContent={trackingHeader}
        loadingState={isLoading}
        data={batches}
      >
        {batches?.map((item, index) => (
          <Batch item={item} key={index} index={index} isLoading={isLoading} />
        ))}
      </Table>
    </div>
  );
};

export default Tracking;

const CreateBatch = ({
  closeModal,
  visibilityState,
  handeleInputChange,
  createBatch,
  isLoading,
}) => {
  return (
    <DefaultModal closeModal={closeModal} visibilityState={visibilityState}>
      <div className='bg-white'>
        <h2 className='my-2 font-medium text-lg text-sec'>
          Select Shipment that will fall into this batch
        </h2>
        <form action='' onSubmit={createBatch} className='flex flex-col'>
          <Input
            id='start_date'
            setItem={handeleInputChange}
            input
            className='border'
            title='Start Date'
            type='date'
          />
          <Input
            id='end_date'
            setItem={handeleInputChange}
            input
            className='border'
            title='End Date'
            type='date'
          />
          <Btn
            className='bg-sec2 text-white my-5'
            text='Save'
            loadingState={isLoading}
          />
        </form>
      </div>
    </DefaultModal>
  );
};
