import React from "react";
import exchange from "../../Assets/images/exchange.png";
const Hero = () => {
  return (
    <div className='h-[300px] relative flex flex-col justify-center'>
      <div className='max-width1'>
        <h2 className='relative z-10 font-bold text-3xl'>Exchange Rate</h2>
      </div>
      <div>
        <img
          src={exchange}
          className='absolute top-0 right-0 object-cover w-full h-[300px]'
          alt='bg'
        />
      </div>
    </div>
  );
};

export default Hero;
