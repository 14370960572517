import React, { useState } from "react";
import Input from "../../Components/Form/Input";
import { IoIosArrowBack } from "react-icons/io";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Btn from "../../Components/Button/btn";
import Sidebar from "../../Components/Auth/sidebar";
import { toast, ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";
import { baseUrl, config } from "../../Utils/constants";
import axios from "axios";
import { useEffect } from "react";
import Timeout from "../../Utils/countdown";
import OTP from "../../Utils/OTP";

const VerifyOTP = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const { token } = useSelector((state) => state.auth);

  const location = useLocation();
  const history = location?.state?.from || null;
  const navigate = useNavigate();

  const [activeSlide, setActiveSlide] = useState(0);
  const [otp, setOtp] = useState("");

  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  let msg;

  useEffect(() => {
    if (history === "login") {
      setActiveSlide(0);
    } else if (history === "signup") {
      setActiveSlide(1);
    }
  }, [history]);

  const getOTP = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const url = `${baseUrl}resendotp`;
    try {
      const res = await axios.post(url, { email }, config(token));
      console.log(res);
      msg = res.data.message;
      toast.success(msg);
      setActiveSlide(1);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      msg = error.response.data.message;
      toast.error(msg);
      setIsLoading(false);
    }
  };

  const verify = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const url = `${baseUrl}verifyotp`;
    try {
      const res = await axios.post(url, { otp }, config(token));
      console.log(res);
      msg = res.data.message;
      toast.success(msg);
      setTimeout(() => {
        navigate("/login");
      }, 2000);

      setIsLoading(false);
    } catch (error) {
      console.log(error);
      msg = error.response.data.message;
      toast.error(msg);
      setIsLoading(false);
    }
  };

  return (
    <div className='relative flex'>
      <ToastContainer autoClose={3000} />
      <Sidebar />
      <div className='w-full md:w-[65%] ml-auto h-[600px]  justify-center my-5 p-8 overflow-y-scroll'>
        <h2 className='font-bold text-2xl'>Email Verification</h2>

        <div className=''>
          {activeSlide === 0 ? (
            <form action='' className='w-full' onSubmit={getOTP}>
              <p className='font-medium text-sm my-3'>
                Verify your email address
              </p>
              <Input
                input
                id='email'
                type='email'
                className={"border-2"}
                title='Enter Email'
                required={true}
                setItem={(e) => setEmail(e.target.value)}
              />

              <Btn
                loadingState={isLoading}
                text={"Send OTP"}
                className='bg-pry w-full my-4 font-bold'
              />
            </form>
          ) : (
            <form className='' onSubmit={verify}>
              <h2 className='text-base my-3'>
                Please enter the OTP sent to your email address
              </h2>

              <OTP OTP={otp} setOTP={setOtp} />
              <div className='my-2'>
                <Timeout duration={756000} resend={getOTP} />
              </div>

              <Btn
                loadingState={isLoading}
                text={"Verify"}
                className='bg-pry my-6'
              />
            </form>
          )}

          <Link to='/' className='flex items-center text-sm my-5'>
            <i>
              <IoIosArrowBack />
            </i>
            <h2>Back to Homepage</h2>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default VerifyOTP;
