import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  admin: null,
  adminToken: null,
  dollarRate: "",
};

const adminSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAdminProfile: (state, { payload }) => {
      state.admin = payload;
    },
    setAdminToken: (state, { payload }) => {
      state.adminToken = payload;
    },
    setDollarRate: (state, { payload }) => {
      state.dollarRate = payload;
    },
  },
});
export const { setAdminProfile, setAdminToken, setDollarRate } =
  adminSlice.actions;
export default adminSlice.reducer;
