function compareDatesInObjects(a, b) {
  const aCreatedDate = new Date(a.created_at);
  const bCreatedDate = new Date(b.created_at);
  if (aCreatedDate > bCreatedDate) return -1;
  if (aCreatedDate < bCreatedDate) return 1;
  return 0;
}

export const sortList = (list) => {
  const sortedArray = list?.sort(compareDatesInObjects);
  return sortedArray;
};

function compareDatesInObject(a, b) {
  const aCreatedDate = new Date(a.updated_at);
  const bCreatedDate = new Date(b.updated_at);
  if (aCreatedDate > bCreatedDate) return -1;
  if (aCreatedDate < bCreatedDate) return 1;
  return 0;
}

export const sortLists = (list) => {
  const sortedArray = list?.sort(compareDatesInObject);
  return sortedArray;
};
