import React from "react";

const Shipment = ({ item }) => {
  const { country, user, address, shipment_status: status } = item || {};
  const { fullname, phone_number, email } = user || {};
  return (
    <>
      <div className='grid gap-2 grid-cols-6 text-xs items-center font-medium'>
        <h2>{fullname}</h2>
        <h2>{email.length > 20 ? `${email.slice(0, 20)}...` : email}</h2>
        <h2>{phone_number}</h2>
        <h2>{address || "nil"}</h2>
        <h2>{country}</h2>
        <h2
          className={`${
            status === "successful"
              ? "bg-pry2 text-green-800"
              : status === "pending"
              ? "bg-blue-100 text-blue-800"
              : status === "processed"
              ? "bg-yellow-100 text-yellow-800"
              : "bg-red-100 text-red-800"
          } p-3 rounded-md font-medium text-center`}
        >
          {status}
        </h2>
      </div>
      <hr className='h-[1px] bg-gray-50 my-4' />
    </>
  );
};

export default Shipment;
