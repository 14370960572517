import React, { useEffect, useState } from "react";
import Export from "../../../Utils/Admin/export";
import Search from "../../../Utils/Admin/search";
import Filters from "../../../Utils/Admin/filters";
import {
  cardUserData,
  fundCardRequest,
  newCardRequest,
} from "../../../Utils/Admin/dCardData";
import CardDetails from "../../../Components/Admin/DollarCard/cardDetails";
import Table from "../../../Components/Admin/Layout/Table";
import FundCard from "../../../Components/Admin/DollarCard/fundCard";
import NewCard from "../../../Components/Admin/DollarCard/newCard";
import Btn from "../../../Components/Button/btn";
import { Spinner } from "react-activity";
import axios from "axios";
import { baseUrl, config } from "../../../Utils/constants";
import { useSelector } from "react-redux";

const VirtualDollarCard = () => {
  const [isLoading, setIsLoading] = useState(true);
  const cardHeader = [
    "Name on Card",
    "D.O.B",
    "Available Funds",
    // "Card Pin",
    "Valid Thru",
    "CVV",
    "Status",
  ];

  const fundCardHeader = [
    "Name on card",
    "Date of birth",
    "Email Address",
    "Card No",
    "Amount to be funded",
    "Available Funds",
    "Date",
    "Time",
    "CVV",
    "valid Thru",
    "Payment",
  ];
  const newCardHeader = [
    "Name on card",
    "Date of birth",
    "Email Address",
    // "Card No",
    "Amount to be funded",
    "Available Funds",
    "Date",
    "Time",
    // "CVV",
    // "valid Thru",
    "Payment",
  ];

  const tabs = ["Dollar Card Users", "Fund Card Request", "New Card Request"];

  const [activeTab, setActiveTab] = useState(0);
  const [headers, setHeaders] = useState(cardHeader);
  const [cardUsers, setCardUsers] = useState([]);

  const { adminToken } = useSelector((state) => state.admin);

  const getAllUsers = async (path) => {
    const url = `${baseUrl}admin/${path}`;
    setIsLoading(true);
    try {
      const { data } = await axios.get(url, config(adminToken));
      setIsLoading(false);
      setCardUsers(data.data);
      console.log(data);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setCardUsers([]);
    }
  };

  useEffect(() => {
    let path;
    if (activeTab === 0) {
      setHeaders(cardHeader);
      path = "get-dollar-cards";
    } else if (activeTab === 1) {
      setHeaders(fundCardHeader);
      path = "";
    } else if (activeTab === 2) {
      setHeaders(newCardHeader);
      path = "";
    }
    getAllUsers(path);
  }, [activeTab]);

  if (isLoading) {
    return (
      <div className='h-[20vh] flex items-center justify-center'>
        <Spinner />
      </div>
    );
  }

  // if (cardUsers.length < 1) {
  //   return (
  //     <div className='h-[20vh] flex items-center justify-center'>
  //       <h2 className='font-medium text-lg'>No users at the moment</h2>
  //     </div>
  //   );
  // }

  return (
    <div>
      <div className='flex justify-between gap-4 items-center my-5 bg-white p-4 rounded-md'>
        <h2 className='font-bold'>
          {tabs[activeTab]}
          <span className='text-gray-500 font-normal'>
            {" "}
            ({cardUsers?.length})
          </span>
        </h2>
        <div className='hidden xl:block'>
          <Search />
        </div>
        <div className='hidden md:block'>
          <Filters />
        </div>
        <Export
          data={cardUsers}
          filename='dollar-card'
          title='Dollar Card'
          headers={headers}
        />
      </div>

      <div className='flex flex-col lg:flex-row justify-between gap-4 lg:items-center my-6'>
        <div className='flex items-center gap-3 overflowX'>
          {tabs.map((tab, ind) => (
            <Btn
              key={ind}
              text={tab}
              className={`${
                activeTab === ind ? "bg-pry2 font-medium" : "bg-gray-100"
              } text-black text-xs`}
              onClick={() => setActiveTab(ind)}
            />
          ))}
        </div>
      </div>

      {activeTab === 0 && (
        <Table
          cols='6'
          minSize='1200px'
          headerContent={cardHeader}
          loadingState={isLoading}
          data={cardUserData}
        >
          {cardUsers?.map((item, index) => (
            <CardDetails
              item={item}
              key={index}
              index={index}
              isLoading={isLoading}
            />
          ))}
        </Table>
      )}

      {activeTab === 1 && (
        <FundCard
          isLoading={isLoading}
          header={fundCardHeader}
          data={cardUsers}
        />
      )}
      {activeTab === 2 && (
        <NewCard
          isLoading={isLoading}
          header={newCardHeader}
          data={cardUsers}
        />
      )}
    </div>
  );
};

export default VirtualDollarCard;
