import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { menus } from "../../../Utils/dashboardMenu";
import { CiLogout } from "react-icons/ci";
import { useDispatch, useSelector } from "react-redux";
import { setLocation } from "../../../Redux/features/dashboardSlice";
import { useEffect } from "react";
import { baseUrl, config } from "../../../Utils/constants";
import axios from "axios";
const Sidebar = ({ isSidebarOpen, closeSidebar }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useSelector((state) => state.auth);

  const logout = () => {};

  const [referralCount, setReferralCount] = useState(0);

  const getTotalReferrals = async () => {
    try {
      const url = `${baseUrl}total-referral`;
      const res = await axios.get(url, config(token));
      setReferralCount(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getTotalReferrals();
  }, []);

  return (
    <div
      className={`fixed top-0 ${
        isSidebarOpen ? "translate-x-0" : "-translate-x-full"
      } md:translate-x-0 transition w-[80%] md:w-[30%] lg:w-[20%] bg-sec text-white h-screen p-8 pt-28 z-10`}
    >
      <div className='h-full overflowHide'>
        {menus?.map(({ menu, url, img }, i) => {
          return (
            <button
              className='flex items-center gap-4 my-8 cursor-pointer'
              key={i}
              onClick={() => {
                dispatch(setLocation(menu));
                closeSidebar();
                navigate(url);
              }}
              disabled={i === 2}
            >
              <img className='w-5' src={img} alt={menu} />
              <h2 className={`text-sm font-medium`}>{menu}</h2>
              {i === 2 && (
                <h2 className='text-xs bg-red-600 p-1 rounded-md italic text-red-200'>
                  (coming soon!!!)
                </h2>
              )}
              {i === 7 && (
                <h2 className='text-xs bg-red-600 text-red-50 rounded-full w-5 h-5 flex items-center justify-center -translate-y-2 -translate-x-3'>
                  {referralCount}
                </h2>
              )}
            </button>
          );
        })}
        <Link
          to='/'
          className='flex items-center gap-4 mt-20 font-medium'
          onClick={logout}
        >
          <i className='text-xl'>
            <CiLogout />
          </i>
          <h2>Logout</h2>
        </Link>
      </div>
    </div>
  );
};

export default Sidebar;
