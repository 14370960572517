import React from "react";
import others from "../../Assets/images/others.png";
import medication from "../../Assets/images/medication.png";
import medications from "../../Assets/images/medications.png";
import battery from "../../Assets/images/battery.png";
import unidentified from "../../Assets/images/unidentified.png";
import animal from "../../Assets/images/animal.png";
import toxic from "../../Assets/images/toxic.png";
import military from "../../Assets/images/military.png";
import firearm from "../../Assets/images/firearm.png";
import substance from "../../Assets/images/substance.png";

const Items = () => {
  const itemsNotShipped = [
    {
      img: substance,
      heading: "Substances",
      details:
        "Poisonous substances, Explosives, Gun powders, Flares, Matches, Gasoline, Diesel, Fuel, Lighters, containing fuel, Pesticides, Herbicides, Fungicides, Radioactive elements",
    },
    {
      img: firearm,
      heading: "Firearm",
      details:
        "Firearms, guns & accessories, Tear gas, mace & pepper spray, Law enforcement striking weapons, including saps, batons & Billy clubs, Handcuffs of any material, Military training equip etc",
    },
    {
      img: military,
      heading: "Military Equipment",
      details:
        "Body armor, helmets, or personal protection articles with Kevlar or ballistic ratings, Military/tactical/police shields, Government, police, or military uniforms, IDs, and Badges etc",
    },
    {
      img: toxic,
      heading: "Toxic Substances",
      details:
        "Lab reagents, biologics, cultures, medical specimens Poisonous substances, Toxic substances, including inhalation hazards Infectious substances etc",
    },
    {
      img: animal,
      heading: "Animals",
      details:
        "Agricultural products, including certain seeds, live or dead plants, unfinished or untreated wood & soil. Skin or leather of reptiles or amphibians, and all animals and plants. ",
    },
    {
      img: unidentified,
      heading: "Unidentified Substances",
      details:
        "Any unidentifiable material, substance, or chemical Tobacco products (including E-cigarettes and vaping products). Coal, and its products.",
    },
    {
      img: medication,
      heading: "Medications",
      details:
        "Including dental & veterinary Prescription medical devices Medical devices, not FDA-approved Prohormones, Human Growth Hormones, stem cell treatments, etc.",
    },
    {
      img: battery,
      heading: "Damaged batteries",
      details:
        "Standalone replacement batteries, Phones batteries, Laptop batteries, Car and Lorry batteries. Lighters and lighting materials of all kinds etc. ",
    },
    {
      img: medication,
      heading: "Medications",
      details:
        "Super Unleaded, Mid-Grade Unleaded, Regular Unleaded, Premium Unleaded, Diesel Fuel, E85 Ethanol, Biodiesel, Propane, Natural Gasoline, Aviation Gasoline, etc.",
    },
    {
      img: others,
      heading: "Others ",
      details:
        "Such as Gold, Diamond, Coral, Brazilian rosewood, Sturgeon or Beluga caviar, Human remain,  Ready to eat meal, Self balancing board, Tear gas, Mace and pepper spray",
    },
  ];
  return (
    <div className='max-width1'>
      <h2 className='font-bold text-xl'>Items we do not ship</h2>
      <div className='gap-8 grid items-cols-1 md:grid-cols-2 lg:grid-cols-3 my-10'>
        {itemsNotShipped.map(({ img, heading, details }, ind) => {
          return (
            <div className='flex gap-3 bg-gray-200 p-4 rounded-md' key={ind}>
              <img src={img} className='w-16 h-fit' alt={heading} />
              <div>
                <h2 className='font-medium text-lg'>{heading}</h2>
                <p className='my-2 text-sm'>{details}</p>
              </div>
            </div>
          );
        })}
      </div>
      <h2 className='font-bold text-xl'>
        If it's legal to ship it, we'll do so safely and quickly.
      </h2>
    </div>
  );
};

export default Items;
