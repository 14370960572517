import React from "react";
import ellipse1 from "../../Assets/images/ellipse1.png";
import ellipse2 from "../../Assets/images/ellipse2.png";
const WhyHero = () => {
  return (
    <div className='bg-green-100 relative'>
      <section className='max-width1'>
        <div className='md:w-1/2 py-12 relative z-10'>
          <h2 className='font-medium text-xl'>Why choose eshopAfrica?</h2>
          <h2 className='font-bold my-3 text-3xl'>
            We are Africa famous delivery company.
          </h2>
          <p className='text-base'>
            eshopAfrica is a purpose-driven technology startup that addresses
            the issue of African enterprises' access to international markets
            for high-quality goods. We are on a mission to close the trade
            deficit, make imports simpler, and give Africans more ability to
            enter international markets.
          </p>
        </div>
      </section>
      <img
        src={ellipse1}
        className='absolute bottom-0 right-0 w-24 md:w-96'
        alt='ellipse'
      />
      <img
        src={ellipse2}
        className='absolute top-0 left-0 w-8 md:w-16'
        alt='ellipse'
      />
    </div>
  );
};

export default WhyHero;
