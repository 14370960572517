import React from "react";
import { useEffect } from "react";
import { useState } from "react";

const CardDetails = ({ item }) => {
  const {
    user,
    dollar_wallet,
    card_firstname,
    card_lastname,
    // pin,
    valid_thru,
    cvv,
  } = item || {};

  const { dob, status } = user || {};
  const { wallet_balance } = dollar_wallet || {};

  useEffect(() => {
    setSelectedAction(status);
  }, []);

  const [selectedAction, setSelectedAction] = useState("");

  useEffect(() => {
    if (status === "active") {
      setSelectedAction("enabled");
    } else if (status === "inactive") {
      setSelectedAction("disabled");
    }
  }, []);

  return (
    <>
      <div className='grid gap-2 grid-cols-6 text-xs items-center font-medium'>
        <h2 className='capitalize'>
          {card_firstname} {card_lastname}
        </h2>
        <h2>{dob || "nil"}</h2>
        <h2>₦ {wallet_balance}</h2>
        {/* <h2>{pin}</h2> */}
        <h2>{valid_thru || "nil"}</h2>
        <h2>{cvv || "nil"}</h2>
        <select
          name=''
          id=''
          className={`${
            selectedAction === "enabled"
              ? "bg-pry2 text-green-800 "
              : selectedAction === "disabled"
              ? "bg-red-200 text-red-600"
              : "bg-gray-200"
          }  p-3 rounded-md font-medium`}
          onChange={(e) => setSelectedAction(e.target.value)}
          value={selectedAction || "select"}
        >
          <option value='select'>Select</option>
          <option value='enabled'>Enabled</option>
          <option value='disabled'>Disabled</option>
          <option value='reset_pin'>Reset Pin</option>
        </select>
      </div>
      <hr className='h-[1px] bg-gray-50 my-4' />
    </>
  );
};

export default CardDetails;
