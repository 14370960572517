import React from "react";
import TableHeader from "./header";
import TableBody from "./body";
import { sortList } from "../../../../Utils/sort";

const Table = ({
  minSize,
  headerContent,
  loadingState,
  data,
  cols,
  children,
}) => {
  return (
    <div className='overflow-x-scroll bg-white shadow-md rounded-md'>
      <TableHeader
        cols={cols}
        minSize={minSize}
        headerContent={headerContent}
      />
      <TableBody loadingState={loadingState} data={data} minSize={minSize}>
        {children}
      </TableBody>
    </div>
  );
};

export default Table;
