import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

const SummaryChart = ({ details }) => {
  // console.log(right, wrong, notAns);
  const data = {
    labels: [
      "Total Shipments",
      "Pending Shipments",
      "Cancelled Shipments",
      "Processed Shipments",
      "Delivered Shipments",
    ],
    datasets: [
      {
        label: "Shipment Chart",
        data: [
          details[0].value,
          details[1].value,
          details[2].value,
          details[3].value,
          details[4].value,
        ],
        backgroundColor: [
          "#FF6666",
          "#F1C93B",
          "#FD841F",
          "#1A5D1A",
          "#1D1CE5",
        ],
        hoverOffset: 4,
      },
    ],
  };
  return (
    <div className='flex flex-col items-center justify-center my-8 md:w-1/2 mx-auto'>
      <Doughnut data={data} />
    </div>
  );
};

export default SummaryChart;
