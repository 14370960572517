import React from "react";

const Record = ({ item }) => {
  const { name, tel, email, referralCode, count, status } = item || {};
  return (
    <>
      <div className='grid gap-2 grid-cols-6 text-xs items-center font-medium'>
        <h2>{name}</h2>
        <h2>{email}</h2>
        <h2>{tel}</h2>
        <h2>{referralCode}</h2>
        <h2>{count}</h2>
        <h2
          className={`${
            status !== "Active"
              ? "bg-red-100 text-red-800"
              : "bg-pry2 text-green-800"
          } p-2 rounded-md text-center`}
        >
          {status}
        </h2>
      </div>
      <hr className='h-[1px] bg-gray-50 my-4' />
    </>
  );
};

export default Record;
