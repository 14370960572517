import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setLocation } from "../../../Redux/features/dashboardSlice";
import Btn from "../../Button/btn";
import Input from "../../Form/Input";
import BackButton from "../../Button/back";
import { IoAddCircle } from "react-icons/io5";
import { MdAdd } from "react-icons/md";

const CreateShipment = ({
  handleInputChange,
  inputItems,
  allCategories,
  handleSubmit,
  isLoading,
  allCountries,
  setActiveSlide,
  addToList,
  categoryList,
  removeCat,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setLocation("Create Shipment"));
  }, []);

  const categories = allCategories?.map((category) => category.category_name);
  const countries = allCountries?.map((country) => country.name);

  return (
    <div>
      <BackButton action={() => setActiveSlide(0)} />
      <h2 className='text-xl font-medium my-4'>Create Shipment</h2>
      <p className='text-sm italic mb-4'>
        You can only create shipment after purchaing items from the third party
        merchant
      </p>
      <div className='bg-white w-full rounded-md shadow-md p-2 md:p-8'>
        <h2 className='text-center mt-4'>
          Enter your order information to allow us process your order
        </h2>
        <form
          action=''
          className='w-full'
          onSubmit={(e) => {
            e.preventDefault();
            if (categoryList.length > 0) {
              handleSubmit();
            } else {
              alert("You must add a category to list before proceeding");
            }
          }}
        >
          <div className='bg-gray-100 p-2 rounded-md my-10'>
            <div className='grid grid-cols-2 md:grid-cols-3 gap-x-4'>
              <Input
                id='category'
                dropdown
                data={["select", ...categories]}
                type='text'
                className={"bg-gray-200 capitalize"}
                title='Category'
                // required={true}
                setItem={handleInputChange}
                value={inputItems.category || ""}
              />
              <Input
                input
                id='tracking_id'
                type='text'
                className={"bg-gray-200"}
                title='Tracking ID'
                // required={true}
                setItem={handleInputChange}
                value={inputItems.tracking_id || ""}
              />
              <div className='flex items-center'>
                <Input
                  input
                  id='quantity'
                  type='number'
                  className={"bg-gray-200"}
                  title='Quantity'
                  // required={true}
                  setItem={handleInputChange}
                  value={inputItems.quantity || ""}
                />
              </div>
            </div>

            <h2
              onClick={addToList}
              className='text-sm italic font-medium select-none cursor-pointer bg-pry px-6 p-3 w-fit'
            >
              Add to list
            </h2>
            <div className='my-4  overflow-x-scroll'>
              {categoryList.length < 1 ? (
                <h2 className='text-sm font-lighter'>
                  You have not added any shipment item
                </h2>
              ) : (
                categoryList.map(({ quantity, category, tracking_id }, ind) => (
                  <div
                    className='grid grid-cols-4 gap-4 text-sm border-b-[1px] py-2 min-w-[800px]'
                    key={ind}
                  >
                    <h2>{category}</h2>
                    <h2>{quantity}</h2>
                    <h2>{tracking_id}</h2>
                    <h2
                      onClick={() => removeCat(ind)}
                      className='select-none text-red-600 cursor-pointer'
                    >
                      Remove
                    </h2>
                  </div>
                ))
              )}
            </div>
          </div>
          <Input
            id='ship_from'
            dropdown
            data={["select", ...countries]}
            type='text'
            className={"bg-gray-100 capitalize"}
            title='Ship From'
            required={true}
            setItem={handleInputChange}
            value={inputItems.ship_from || ""}
          />

          <Input
            textarea
            id='specification'
            type='text'
            className={"bg-gray-100 placeholder:text-xs"}
            title='Detailed items description'
            placeholder={
              "A piece of information that Clearly describes the items being packaged for shipping it should leave no room for guesses so  that eshopafrica can know how best to handle it. "
            }
            required={true}
            setItem={handleInputChange}
            value={inputItems.specification || ""}
          />

          <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
            <Input
              input
              id='delivery_address'
              type='address'
              className={"bg-gray-100"}
              title='Delivery Address & City'
              required={true}
              setItem={handleInputChange}
              value={inputItems.delivery_address || ""}
            />
            <Input
              input
              id='country'
              type='address'
              className={"bg-gray-100"}
              title='Country'
              required={true}
              setItem={handleInputChange}
              value={inputItems.country || ""}
            />
            <Input
              input
              id='state'
              type='address'
              className={"bg-gray-100"}
              title='State'
              required={true}
              setItem={handleInputChange}
              value={inputItems.state || ""}
            />
            <Input
              input
              id='zip_code'
              type='number'
              className={"bg-gray-100"}
              title='Postal Code/Zip Code'
              required={true}
              setItem={handleInputChange}
              value={inputItems.zip_code || ""}
            />
          </div>
          <Btn
            loadingState={isLoading}
            text={"Continue"}
            className='w-full bg-pry my-6'
          />
        </form>
      </div>
    </div>
  );
};

export default CreateShipment;
