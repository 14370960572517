import React, { useState } from "react";
import Btn from "../../Button/btn";
import DefaultModal from "../../Modals/defaultModal";
import Input from "../../Form/Input";
import axios from "axios";
import { baseUrl, config } from "../../../Utils/constants";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
const Batch = ({ item, index }) => {
  const { start_date, end_date, batch_number, id } = item || {};
  const [isLoading, setIsLoading] = useState(false);
  const [details, setDetails] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { adminToken } = useSelector((state) => state.admin);

  const sendComment = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    const url = `${baseUrl}admin/update-batch`;
    try {
      const res = await axios.post(
        url,
        { batch_id: id, details },
        config(adminToken)
      );
      console.log(res);
      toast.success(res.data.message);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error(error?.response.data.message);
    }
    setIsModalOpen(false);
  };

  return (
    <>
      <ToastContainer autoClose={3000} />
      <div className='grid gap-2 grid-cols-5 text-xs items-center font-medium'>
        <h2>{index + 1}</h2>
        <h2>{batch_number}</h2>
        <h2>{start_date}</h2>
        <h2>{end_date}</h2>
        <Btn
          text='Update'
          className='text-sec bg-green-50 text-sm'
          onClick={() => setIsModalOpen(true)}
        />
      </div>
      <hr className='h-[1px] bg-gray-50 my-4' />

      <DefaultModal
        visibilityState={isModalOpen}
        closeModal={() => setIsModalOpen(false)}
      >
        <form action='' onSubmit={sendComment}>
          <Input
            title='Enter comment about this batch'
            setItem={(e) => setDetails(e.target.value)}
            textarea
            id='details'
            placeholder='Enter comment'
            className='bg-gray-100'
          />
          <Btn
            loadingState={isLoading}
            className='bg-sec2 text-white text-sm'
            text='Send Comment'
          />
        </form>
      </DefaultModal>
    </>
  );
};

export default Batch;
