import React from "react";

const Offices = () => {
  return (
    <div className='max-width1 my-16'>
      <h2 className='font-bold text-xl'>US Office</h2>
      <div className='bg-gray-200 rounded-md p-5 my-5 grid md:grid-cols-2 gap-3 '>
        <div>
          <h4 className='font-medium text-base'>Address</h4>
          <p className='my-2'>
            1009 country club rd, 46234 Indianapolis IN. <br />
            <br /> +1 (317) 529-2934
          </p>
        </div>
        <div>
          <h4 className='font-medium text-base'>Office Hours</h4>
          <p className='my-2'>
            9am - 5pm Monday to Friday Closed on Saturday and Sunday
          </p>
        </div>
      </div>
      <h2 className='font-bold text-xl mt-10'>Nigeria Office</h2>
      <div className='bg-gray-200 rounded-md p-5 my-5 grid md:grid-cols-2 gap-3 '>
        <div>
          <h4 className='font-medium text-base'>Address</h4>
          <p className='my-2'>
            7b Budland street Akiode Berger Lagos. <br />
            <br /> +2349033080167
          </p>
        </div>
        <div>
          <h4 className='font-medium text-base'>Office Hours</h4>
          <p className='my-2'>
            9am - 5pm Monday to Friday Closed on Saturday and Sunday
          </p>
        </div>
      </div>
    </div>
  );
};

export default Offices;
