import React, { useEffect } from "react";
import Help from "../../Components/Contact/help";
import ContactHero from "../../Components/Contact/hero";
import Offices from "../../Components/Contact/offices";

const Contact = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <div>
      <ContactHero />
      <Offices />
      <Help />
    </div>
  );
};

export default Contact;
