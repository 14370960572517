import React from "react";
import Calculator from "../../Pages/Dashboard/Calculator";
import { Link } from "react-router-dom";
import { AnimationOnScroll } from "react-animation-on-scroll";
const Rate = () => {
  return (
    <div className='py-10 bg-sec'>
      <div className='max-width1 grid grid-cols-1 md:grid-cols-2 items-center  gap-8'>
        <div className='text-white'>
          <AnimationOnScroll animateIn='animate__backInLeft'>
            <h2 className='font-bold text-2xl md:text-4xl'>
              Want to know how much it will cost you to ship to your doorstep in
              Nigeria?
            </h2>
          </AnimationOnScroll>
          <p className='my-4 text-lg'>
            Enter the weight of your shipment and get an estimated shipping
            cost.
          </p>
          <div className='flex gap-3'>
            <h4 className='font-medium'>Need more information? </h4>
            <AnimationOnScroll animateIn='animate__pulse animate__infinite'>
              <Link to='/pricing' className='text-pry underline'>
                Go to Pricing
              </Link>{" "}
            </AnimationOnScroll>
          </div>
        </div>

        <Calculator />
      </div>
    </div>
  );
};

export default Rate;
