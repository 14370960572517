import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Btn from "../../Button/btn";
import OTPInput from "../../../Utils/OTP";

const ConfirmPin = ({
  confirmPinDetails,
  setConfirmPinDetails,
  isLoading,
  createCardPin,
}) => {
  return (
    <div>
      <h2 className='font-medium text-2xl'>Type it again for confirmation</h2>
      <p className='my-2'>
        Please remenber this pin. It will be used to keep your account secure
      </p>
      <form
        className=''
        onSubmit={(e) => {
          e.preventDefault();
          createCardPin();
        }}
      >
        <OTPInput OTP={confirmPinDetails} setOTP={setConfirmPinDetails} />
        <Btn loadingState={isLoading} text={"Finish"} className='bg-pry my-6' />
      </form>
    </div>
  );
};

export default ConfirmPin;
