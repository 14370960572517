import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  dashLocation: "Home",
  dollarCardState: false,
  notificationCount: 0,
  dollarRate: 0,
};

const dashboardSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLocation: (state, { payload }) => {
      state.dashLocation = payload;
    },
    setDollarCardState: (state) => {
      state.dollarCardState = true;
    },

    setNotificationCount: (state, { payload }) => {
      state.notificationCount = payload;
    },
    setUserDollarRate: (state, { payload }) => {
      state.dollarRate = payload;
    },
  },
});

export const {
  setLocation,
  setDollarCardState,
  setNotificationCount,
  setUserDollarRate,
} = dashboardSlice.actions;
export default dashboardSlice.reducer;
