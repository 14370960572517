import React, { useEffect, useState } from "react";
import img from "../../../Assets/images/userEmoji.png";
import { BsThreeDotsVertical } from "react-icons/bs";
import DefaultModal from "../../Modals/defaultModal";
import Input from "../../Form/Input";
import Btn from "../../Button/btn";
import { baseUrl, config, imageUrl } from "../../../Utils/constants";
import axios from "axios";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { Spinner } from "react-activity";

const User = ({ item }) => {
  const {
    fullname: name,
    phone_number: tel,
    email,
    referral_code,
    address,
    status,
    id,
    profile_photo,
  } = item || {};

  const [selectedAction, setSelectedAction] = useState("");
  const [noteDetails, setNoteDetails] = useState({});
  const { adminToken } = useSelector((state) => state.admin);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [statusLoader, setStatusLoader] = useState(false);
  const [profileImage, setProfileImage] = useState(img);

  useEffect(() => {
    if (profile_photo) {
      setProfileImage(`${imageUrl}${profile_photo}`);
    }
  }, []);

  const handleInputChange = (e) => {
    setNoteDetails({ ...noteDetails, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (status === "active") {
      setSelectedAction("enabled");
    } else if (status === "inactive") {
      setSelectedAction("disabled");
    }
  }, []);

  const updateStatus = async (e) => {
    const url = `${baseUrl}admin/update-status`;
    let userStatus;
    setStatusLoader(true);
    const value = e.target.value;
    setSelectedAction(value);
    try {
      if (value === "enabled") {
        userStatus = "active";
      } else if (value === "disabled") {
        userStatus = "inactive";
      }
      const res = await axios.post(
        url,
        { status: userStatus, user_id: id },
        config(adminToken)
      );
      setStatusLoader(false);
      console.log(res);
      toast.success(res.data.message);
    } catch (error) {
      setStatusLoader(false);
      console.log(error);
      toast.error(error.response.data.message);
    }
  };

  const sendNotification = async (e) => {
    const url = `${baseUrl}admin/send-single-notification`;
    e.preventDefault();
    setIsLoading(true);
    try {
      const res = await axios.post(
        url,
        { ...noteDetails, user_id: id },
        config(adminToken)
      );
      setIsLoading(false);
      toast.success(res.data.message);
      setIsModalOpen(false);
    } catch (error) {
      setIsLoading(false);
      toast.error(error.response.data.message);
    }
  };

  return (
    <>
      <ToastContainer autoClose={3000} />
      <div className='grid gap-2 grid-cols-7 text-xs items-center font-medium'>
        <img
          src={profileImage}
          className='w-10 h-10 rounded-full object-cover'
          alt='user'
        />
        <h2>{name}</h2>
        <h2>{tel}</h2>
        <h2>{email.length > 20 ? `${email.slice(0, 20)}...` : email}</h2>
        <h2>{referral_code}</h2>
        <h2>{address || "nil"}</h2>
        <div className='flex items-center gap-2 justify-center'>
          {statusLoader ? (
            <Spinner />
          ) : (
            <select
              name=''
              id=''
              className={`${
                selectedAction === "enabled"
                  ? "bg-pry2 text-green-800 "
                  : selectedAction === "disabled"
                  ? "bg-red-200 text-red-600"
                  : "bg-gray-200"
              }  p-3 rounded-md font-medium w-full`}
              onChange={updateStatus}
              value={selectedAction || "select"}
            >
              <option value='select'>Select</option>
              <option value='enabled'>Enabled</option>
              <option value='disabled'>Disabled</option>
            </select>
          )}

          <i>
            <BsThreeDotsVertical
              size={20}
              color='gray'
              className='cursor-pointer'
              onClick={() => setIsModalOpen(true)}
            />
          </i>
        </div>
      </div>
      <hr className='h-[1px] bg-gray-50 my-4' />
      <DefaultModal
        closeModal={() => setIsModalOpen(false)}
        visibilityState={isModalOpen}
      >
        <div className='bg-white'>
          <form action='' onSubmit={sendNotification} className='flex flex-col'>
            <Input
              id='title'
              setItem={handleInputChange}
              input
              className='border'
              title='Message Title'
            />
            <Input
              textarea
              className='border'
              id='message'
              setItem={handleInputChange}
              title='Message Description (Not more than 160 characters)'
            />
            <Btn
              loadingState={isLoading}
              className='bg-sec2 text-white my-5'
              text='Send Notification'
            />
          </form>
        </div>
      </DefaultModal>
    </>
  );
};

export default User;
