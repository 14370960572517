import React, { useEffect, useState } from "react";
import Btn from "../../Components/Button/btn";
import Input from "../../Components/Form/Input";
import { MdContentCopy } from "react-icons/md";
import { baseUrl, config, copyText } from "../../Utils/constants";
import axios from "axios";
import { useSelector } from "react-redux";
import { Spinner } from "react-activity";
const Address = () => {
  const [addressDetails, setAddressDetails] = useState({});
  const [activeBtn, setActiveBtn] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const { token } = useSelector((state) => state.auth);

  const getAddress = async (id) => {
    setActiveBtn(id);
    setIsLoading(true);
    let url;
    if (id === 1) {
      url = `${baseUrl}get-address/China`;
    } else {
      url = `${baseUrl}get-address/USA`;
    }
    try {
      const res = await axios.get(url, config(token));
      const address = res.data;
      setAddressDetails(address);
      console.log(res);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAddress();
  }, []);

  const btn = ["US Address", "China Address"];
  if (isLoading) {
    return (
      <div className='flex items-center justify-center'>
        <Spinner />
      </div>
    );
  }

  return (
    <div>
      <div className='flex flex-row gap-4 my-2'>
        {btn.map((item, ind) => (
          <button
            className={`${
              activeBtn === ind
                ? "border-b-green-500 border-b-2"
                : "border-b-gray-200 border-b-2"
            }`}
            onClick={() => getAddress(ind)}
            key={ind}
          >
            {item}
          </button>
        ))}
      </div>

      <div className='bg-white w-full rounded-md shadow-md p-8'>
        <h2 className='text-center my-6'>
          Use the information below as your shipping address when shopping
          online. Please add the unique code to your name. We’ll receive and
          process the package in your name.
        </h2>
        <form action='' className='w-full'>
          <div className='grid grid-cols-1 md:grid-cols-2 gap-4 '>
            <div className='relative'>
              <Input
                input
                id='full_name'
                type='name'
                className={"bg-gray-100"}
                title='Full Name'
                required={true}
                value={addressDetails?.name}
                readOnly={true}
              />
              <i
                className='text-gray-400 absolute bottom-8 right-4'
                onClick={() => copyText(addressDetails?.full_name)}
              >
                <MdContentCopy />
              </i>
            </div>
            <div className='relative'>
              <Input
                input
                id='unique_code'
                type='text'
                className={"bg-gray-100"}
                title='Unique Code'
                required={true}
                value={addressDetails?.unique_id}
                readOnly={true}
              />
              <i
                className='text-gray-400 absolute bottom-8 right-4'
                onClick={() => copyText(addressDetails?.unique_code)}
              >
                <MdContentCopy />
              </i>
            </div>
            <div className='relative'>
              <Input
                input
                id='address'
                type='text'
                className={"bg-gray-100"}
                title='Address'
                required={true}
                value={addressDetails?.address}
                readOnly={true}
              />
              <i
                className='text-gray-400 absolute bottom-8 right-4'
                onClick={() => copyText(addressDetails?.address)}
              >
                <MdContentCopy />
              </i>
            </div>
            <div className='relative'>
              <Input
                input
                id='tel'
                type='tel'
                className={"bg-gray-100"}
                title='Phone Number'
                required={true}
                value={addressDetails?.phone_number}
                readOnly={true}
              />
              <i
                className='text-gray-400 absolute bottom-8 right-4'
                onClick={() => copyText(addressDetails?.phone_number)}
              >
                <MdContentCopy />
              </i>
            </div>
            <div className='relative'>
              <Input
                input
                id='city'
                type='text'
                className={"bg-gray-100"}
                title='City'
                required={true}
                value={addressDetails?.city}
                readOnly={true}
              />
              <i
                className='text-gray-400 absolute bottom-8 right-4'
                onClick={() => copyText(addressDetails?.city)}
              >
                <MdContentCopy />
              </i>
            </div>
            <div className='relative'>
              <Input
                input
                id='state'
                type='text'
                className={"bg-gray-100"}
                title='State'
                required={true}
                value={addressDetails?.state}
                readOnly={true}
              />
              <i
                className='text-gray-400 absolute bottom-8 right-4'
                onClick={() => copyText(addressDetails?.state)}
              >
                <MdContentCopy />
              </i>
            </div>
            <div className='relative'>
              <Input
                input
                id='zip'
                className={"bg-gray-100"}
                title='Zip Code'
                required={true}
                value={addressDetails?.zip_code}
                readOnly={true}
              />
              <i
                className='text-gray-400 absolute bottom-8 right-4'
                onClick={() => copyText(addressDetails?.zip_code)}
              >
                <MdContentCopy />
              </i>
            </div>
            <div className='relative'>
              <Input
                input
                id='country'
                type='text'
                className={"bg-gray-100"}
                title='Country'
                required={true}
                value={addressDetails?.country}
                readOnly={true}
              />
              <i
                className='text-gray-400 absolute bottom-8 right-4'
                onClick={() => copyText(addressDetails?.country)}
              >
                <MdContentCopy />
              </i>
            </div>
          </div>
          <Btn
            text={"Continue"}
            className='w-full bg-pry my-6'
            // onClick={nextSlide}
          />
        </form>
      </div>
    </div>
  );
};

export default Address;
