import React from "react";
import chat from "../../Assets/images/forum.png";
import phone from "../../Assets/images/phone.png";
import message from "../../Assets/images/message.png";
import Btn from "../Button/btn";
const Help = () => {
  return (
    <section className='max-width1'>
      <div className='bg-sec text-white rounded-md p-5 flex flex-col items-center justify-center'>
        <h2 className='font-bold text-xl'>Need Help?</h2>
        <div className='flex flex-col md:flex-row items-center justify-center gap-4 my-8'>
          <div className='flex flex-col items-center'>
            <img src={chat} className='w-10 h-9' alt='chat' />
            <Btn
              className='p-2 rounded-md border-2 border-white text-white my-4'
              text='Chat with us'
            />
          </div>
          <div className='flex flex-col items-center'>
            <img src={phone} className='w-10 h-9' alt='chat' />
            <Btn
              className='p-2 rounded-md border-2 border-white text-white my-4'
              text='Call us'
            />
          </div>
          <div className='flex flex-col items-center'>
            <img src={message} className='w-10 h-9' alt='chat' />
            <Btn
              className='p-2 rounded-md border-2 border-white text-white my-4'
              text='Email us'
              onClick={() => window.open("mailto:info@eshopafrica.co")}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Help;
