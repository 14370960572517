import React from "react";
import NewCardDetails from "./newCardDetails";
import Table from "../Layout/Table";

const NewCard = ({ data, header, isLoading }) => {
  return (
    <Table
      cols='12'
      minSize='1800px'
      headerContent={header}
      loadingState={isLoading}
      data={data}
    >
      {data?.map((item, index) => (
        <NewCardDetails
          item={item}
          key={index}
          index={index}
          isLoading={isLoading}
        />
      ))}
    </Table>
  );
};

export default NewCard;
