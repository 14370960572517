import React, { useState } from "react";
import image from "../../Assets/images/shop.png";
import Btn from "../Button/btn";
import DefaultModal from "../Modals/defaultModal";

const Shop = () => {
  const [isModalShown, setIsModalShown] = useState(false);
  return (
    <div className='bg-green-200 py-10'>
      <div className='max-width1 flex flex-col md:flex-row-reverse items-center'>
        <div className='md:w-[50%]'>
          <img src={image} alt='shop' />
        </div>
        <div className='md:w-[50%]'>
          <h2 className='text-3xl font-medium'>
            Shop and ship with ease live a stress free life.
          </h2>
          <p className='my-4 text-sec'>
            Experience what it takes to shop and ship with ease.{" "}
          </p>
          <Btn
            onClick={() => setIsModalShown(true)}
            text='Get Quote'
            className='bg-pry'
          />
        </div>
      </div>
      <DefaultModal
        visibilityState={isModalShown}
        closeModal={() => setIsModalShown(false)}
      >
        <h2>
          To get a quote kindly reach out to our customer service via
          <a
            href='mailTo:info@eshopafrica.co'
            className='text-green-600 italic underline'
          >
            {" "}
            info@eshopafrica.co
          </a>
        </h2>
      </DefaultModal>
    </div>
  );
};

export default Shop;
