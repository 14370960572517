import React from "react";
import del from "../../Assets/images/del2.png";
import bag from "../../Assets/images/bag2.png";
import location from "../../Assets/images/location.png";

const Ship = () => {
  const howToShip = [
    {
      img: location,
      title: "Use our US address",
      details:
        "Get a valid US address when you shop with eshopAfrica.  use as your check out address or have your package sent  by your loved ones",
    },
    {
      img: bag,
      title: "Shop at any of your favorite stores",
      details:
        "Shop in any of your favorite  in the USA and have your  package delivered to us.",
    },
    {
      img: del,
      title: "Deliver anywhere  in Nigeria",
      details:
        "We will have your shipment  delivered to you anywhere in Nigeria.",
    },
  ];
  return (
    <div className='max-width1 my-10'>
      <h2 className='text-center p-3 font-bold text-2xl'>
        How to ship with eshopAfrica
      </h2>
      <div className='my-6 grid grid-cols-1 md:grid-cols-3 sm:grid-cols-2 gap-5'>
        {howToShip.map(({ img, title, details }, ind) => {
          return (
            <div
              className='p-4 rounded-md shadow-sm flex flex-col items-center text-center'
              key={ind}
            >
              <div className='bg-sec w-12 h-12 rounded-full flex items-center justify-center'>
                <img src={img} alt={title} />
              </div>
              <h2 className='font-bold my-4'>{title}</h2>
              <p>{details}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Ship;
