import React, { useState } from "react";
import { MdClose } from "react-icons/md";
import Btn from "../../Button/btn";
import { ToastContainer, toast } from "react-toastify";
import { baseUrl, copyText } from "../../../Utils/constants";
import axios from "axios";
import { sortLists } from "../../../Utils/sort";
import Timestamp from "../../../Utils/timeStamp";

const TrackShipment = ({ isSidebarOpen, setIsbarOpen }) => {
  const [slideStatus, setSlideStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [trackDetails, setTrackDetails] = useState([]);
  const [trackID, setTrackID] = useState(null);

  const trackShipment = async () => {
    setIsLoading(true);
    const url = `${baseUrl}track`;
    try {
      const res = await axios.post(url, { tracking_number: trackID });
      const message = res.data.message;
      if (message) {
        toast(message);
      }
      setTrackDetails(res.data.data);
      setSlideStatus(true);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  sortLists(trackDetails);

  return (
    <div
      className={`${
        isSidebarOpen ? "translate-x-0" : "translate-x-full"
      } fixed w-full md:w-[50%] h-screen bg-white top-0 right-0 transition p-8 py-12 z-20 shadow-md`}
    >
      <div className='flex items-center justify-between'>
        <h2 className='text-lg font-medium'>Track Shipment</h2>
        <i className='text-xl' onClick={() => setIsbarOpen(false)}>
          <MdClose />
        </i>
      </div>

      <hr className='-mx-8 my-6' />

      <div className='my-6'>
        <h2 className='text-sm'>Enter Shipment ID</h2>
        <form
          action=''
          onSubmit={(e) => {
            e.preventDefault();
            trackShipment();
          }}
        >
          <input
            onChange={(e) => setTrackID(e.target.value)}
            type='number'
            className='rounded-md p-2 my-2 border-2 w-full'
          />
        </form>
      </div>

      {slideStatus && (
        <div>
          {trackDetails?.map(({ trak_details, updated_at }, ind) => {
            return (
              <div key={ind} className='flex gap-4 my-6'>
                <div className='w-3 h-3 rounded-full bg-green-500 mt-1' />
                <div className='text-sm'>
                  <Timestamp time={updated_at} />
                  <h2 className='my-2'>{trak_details}</h2>
                </div>
              </div>
            );
          })}

          <hr className='-mx-8 my-6' />

          <div className='flex justify-between gap-4 my-5'>
            <div className='text-sm'>
              <h2>Tracking ID</h2>
              <h2>{trackID}</h2>
            </div>
            <h2
              onClick={() => copyText(trackID)}
              className='italic text-xs text-white bg-green-500 rounded-md h-fit p-1 px-3 select-none cursor-pointer'
            >
              Copy
            </h2>
          </div>
        </div>
      )}

      <hr className='-mx-8 my-6' />
      {slideStatus ? (
        <Btn
          onClick={() => {
            setIsbarOpen(false);
            setSlideStatus(false);
          }}
          text='Close'
          className='bg-pry w-full'
          loadingState={isLoading}
        />
      ) : (
        <Btn
          onClick={() => {
            if (trackID) {
              trackShipment();
            } else {
              toast.error("Shipment ID can't be empty");
            }
          }}
          className='bg-pry w-full'
          text='Track'
          loadingState={isLoading}
        />
      )}

      <ToastContainer autoClose={2000} />
    </div>
  );
};

export default TrackShipment;
