import React from "react";
const Section = () => {
  return (
    <div className='flex flex-col items-center text-center max-width1 py-10'>
      <h2 className='font-bold text-2xl text-gray-800'>
        Personal Shopping Services
      </h2>
      <p className='my-4 text-base text-sec font-medium'>
        Are you exhausted from searching through endless stores and websites in
        pursuit of the ideal shopping or fashion items that complement your
        one-of-a-kind style? Yearning for a more streamlined and delightful
        shopping adventure? Your search ends here! Our personal shopping
        services are poised to transform your closet and enhance your shopping
        journey. Simply send us the store details, desired product, and any
        related links to info@eshopafrica.co and we'll take care of the rest."
      </p>

      <a
        href='mailto:info@eshopafrica.co'
        className='bg-pry rounded-md p-3 px-6'
      >
        Send us a mail
      </a>
    </div>
  );
};

export default Section;
