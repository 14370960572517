import React from "react";
import { BiExport } from "react-icons/bi";
import { ExportToCsv } from "export-to-csv";

const Export = ({ data, filename, title, headers }) => {
  const options = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    showTitle: true,
    title,
    useTextFile: false,
    useBom: true,
    filename,
    // useKeysAsHeaders: true,
    headers,
  };

  const csvExporter = new ExportToCsv(options);
  return (
    <button
      className='flex items-center gap-2 bg-[#055429] text-white rounded-md p-3 px-6 border border-white shadow-md hover:bg-green-600 transition hover:scale-95 text-sm'
      onClick={() => csvExporter.generateCsv(data)}
    >
      <BiExport className='text-xl' />
      Export
    </button>
  );
};

export default Export;
