import React from "react";
import ellipse1 from "../../Assets/images/ellipse1.png";
import ellipse2 from "../../Assets/images/ellipse2.png";
const GuidelinesHero = () => {
  return (
    <div className='bg-green-100 relative'>
      <section className='max-width1'>
        <div className='md:w-1/2 py-12 relative z-10'>
          <h2 className='font-medium text-xl'>Shipping Guideline</h2>
          <h2 className='font-bold my-3 text-3xl'>
            We operate according to international shipping standard
          </h2>
          <p className='text-base'>
            At eShopAfrica, we are dedicated to following international shipping
            guidelines to ensure your products safe, prompt delivery. We
            understand that international shipping can be complex and that
            policies vary from country to country. That’s why we stay current on
            the most recent regulations and protocols to ensure that your items
            are shipped securely and efficiently.
          </p>
        </div>
      </section>
      <img
        src={ellipse1}
        className='absolute bottom-0 right-0 w-24 md:w-96'
        alt='ellipse'
      />
      <img
        src={ellipse2}
        className='absolute top-0 left-0 w-8 md:w-16'
        alt='ellipse'
      />
    </div>
  );
};

export default GuidelinesHero;
