import React from "react";
import why from "../../Assets/images/why.png";
const Why = () => {
  return (
    <div className='max-width1 grid grid-cols-1 md:grid-cols-2 gap-4 my-16'>
      <div>
        <h2 className='font-bold text-xl my-4'>Why choose eshopAfrica?</h2>
        <p className='text-sm'>
          <strong> Advantages of Shopping and shipping with eshopafrica</strong>
          <br />
          With eshopAfrica's considerable experience, industry-leading
          competence, and unrivaled member care, you can enjoy a speedy and
          worry-free shipping experience. <br />
          <br />
          <strong>
            Our Guaranteed Pricing in the United States and other countries
          </strong>
          <br /> Our ground-breaking new pricing approach eliminates the
          ambiguity surrounding international shipping charges. It's only
          available at eshopAfrica. <br />
          <br /> <strong>Consolidation of eshopAfrica Packages</strong>
          <br /> When compared to shipping directly from the online shop, using
          our premier service to bundle numerous products into one shipment can
          save you a lot of money.
          <br />
          <br />
          <strong>Carrier Rates at a Discount </strong>
          <br /> Due to our high shipping volume and long-term agreements with
          our partners, we can offer you exceptional discount rates. <br />
          <br />
          <strong>
            {" "}
            Pledge to expedite delivery in the United States of America and
            other countries{" "}
          </strong>
          <br />
          We are dedicated to providing the most expedient shipping choices. Our
          crew works seven days a week to ensure that shipments leave our
          warehouse within 24 hours, ensuring that your items arrive as quickly
          as possible. <br />
          <br />
          <strong>Packing & Personal Care are of the highest quality.</strong>
          <br />
          We hand-pack and check each shipment, ensuring that your valued
          purchases are well-protected. We also remove any extraneous packaging
          and consolidate your shipments for optimal savings. <br />
          <br />
          <strong>Customs & Regulations</strong>
          <br /> eshopAfrica takes care of your export paperwork, and our
          knowledge of compliance allows us to safely ship a variety of US and
          other country products to you that most others can't. <br />
          <br />
          <strong>Shop with confidence! </strong>
          <br /> If your goods arrive at eshopAfrica damaged, not what you
          expected, or in the wrong size or color, we are delighted to assist
          you with product returns to US retailers.
        </p>
      </div>
      <div className='flex items-center justify-center my-10 md:my-0'>
        <img className='md:w-2/3' src={why} alt='why' />
      </div>
    </div>
  );
};

export default Why;
