import React, { useEffect, useState } from "react";
import Export from "../../../Utils/Admin/export";
import Search from "../../../Utils/Admin/search";
import Filters from "../../../Utils/Admin/filters";
import Table from "../../../Components/Admin/Layout/Table";
// import { ordersData } from "../../../Utils/Admin/ordersData";
import Order from "../../../Components/Admin/Orders/order";
import axios from "axios";
import { useSelector } from "react-redux";
import { baseUrl, config } from "../../../Utils/constants";
import { sortList } from "../../../Utils/sort";
import { Spinner } from "react-activity";

const Orders = () => {
  const [isLoading, setisLoading] = useState(false);
  const [ordersData, setOrdersData] = useState([]);

  const { adminToken } = useSelector((state) => state.admin);
  const ordersHeader = [
    "Name",
    "Address",
    "Unique Code/Date",
    "Tel/Email",
    "Tracking ID",
    "Dimension/Batch No",
    "Amount",
    "Delivery Status",
    "Payment Status",
    "Ship From",
    "item Details",
    "Action",
  ];

  const getOrders = async () => {
    const url = `${baseUrl}admin/get-shipments`;
    setisLoading(true);
    try {
      const { data } = await axios.get(url, config(adminToken));
      setisLoading(false);
      setOrdersData(data.data);
    } catch (error) {
      console.log(error);
      setisLoading(false);
    }
  };

  useEffect(() => {
    getOrders();
  }, []);

  sortList(ordersData);

  if (isLoading) {
    return (
      <div className='h-[20vh] flex items-center justify-center'>
        <Spinner />
      </div>
    );
  }

  if (ordersData.length < 1) {
    return (
      <div className='h-[20vh] flex items-center justify-center'>
        <h2 className='font-medium text-lg'>No order at the moment</h2>
      </div>
    );
  }
  // console.log(ordersData);
  return (
    <div>
      <div className='flex justify-between gap-4 items-center my-5 bg-white p-4 rounded-md'>
        <h2 className='font-bold'>
          Orders and Payments
          <span className='text-gray-500 font-normal'>
            ({ordersData?.length})
          </span>
        </h2>
        <div className='hidden xl:block'>
          <Search />
        </div>
        <div className='hidden md:block'>
          <Filters />
        </div>
        <Export
          data={ordersData}
          filename='orders'
          title='Orders and Payments'
          headers={ordersHeader}
        />
      </div>

      <Table
        cols='12'
        minSize='2300px'
        headerContent={ordersHeader}
        loadingState={isLoading}
        data={ordersData}
      >
        {ordersData?.map((item, index) => (
          <Order
            getOrders={getOrders}
            item={item}
            key={index}
            index={index}
            isLoading={isLoading}
          />
        ))}
      </Table>
    </div>
  );
};

export default Orders;
